// Packages
import React, { useContext, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { Box, Button, Checkbox, CircularProgress, Grid, TextField, Typography } from '@mui/material';
import get from 'lodash/get';
import { noop } from 'lodash';
import PropTypes from 'prop-types';

// Relatives
import ModalTerms from '../modals/ModalTerms';
import ModalPrivacy from '../modals/ModalPrivacy';
import http from '../../../services/api/http';
import AppContext from '../../../contexts/AppContext';
import PhoneInput from '../../../components/PhoneInput/PhoneInput';
import LayoutContext from '../../../contexts/LayoutContext';

const LoginPhone = props => {
  const { onSubmit } = props;
  /**
   * App Contexts & Params
   */
  const {
    setHeader,
    setSubtitle,
    setPageTitle,
    setPageClass
  } = useContext(LayoutContext);
  /**
   * App State
   */
  const [openTermsAndConditions, setOpenTermsAndConditions] = useState(false);
  const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false);
  const [loading, setLoading] = useState(false);
  const [timezone, setTimezone] = useState('');
  const [country, setCountry] = useState('');
  const [language] = useState(() => navigator.language || navigator.userLanguage);
  const { apiHost } = useContext(AppContext);
  const loginForm = useForm({
    defaultValues: {
      phone_number: '+1',
      termsAndConditions: ''
    }
  });
  /**
   * Handlers
   */
  const openPrivacyPolicyModal = () => setOpenPrivacyPolicy(true);
  const getCurrentLocation = async () => {
    const response = await http('https://ip-api.com')
      .get('/json')
      .catch(() => {
      });

    if (!response || !response.data) {
      setTimezone('America/New_York');
      setCountry('US');

      return;
    }

    const { timezone, countryCode } = get(response, 'data', {});
    setTimezone(timezone);
    setCountry(countryCode);
  };
  /**
   * Effects
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setPageTitle('Login');
    setHeader(() =>
      <Typography
        color="primary"
        variant="h1"
        className="pj-login-header"
      >Welcome</Typography>
    );
    setSubtitle(() =>
      <Typography
        variant="body2"
        className="pj-login-subtitle"
      >Just a few simple steps to <br /> create your account</Typography>
    );
    setPageClass('Login');
    getCurrentLocation();
    // TODO: eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSubmit = async formData => {
    const { phone_number: phoneNumber } = formData;
    setLoading(true);
    const response = await http(apiHost)
      .post('/api/web/v1/new-login-flow/generate-login-process', {
        phone_number: phoneNumber,
        country,
        timezone,
        language
      })
      .catch(() => {
      });

    setLoading(false);
    const { success, error, data } = get(response, 'data', {});
    if (!success && error) {
      error.fieldErrors.forEach(({ field, message }) => loginForm.setError(field, { type: 'required', message }));

      return;
    }

    onSubmit({ mobilePhone: phoneNumber, confirmationToken: data.confirmationToken });
  };

  return (
    <>
      <Box className={loading ? 'loading-overlay' : 'loading-overlay hidden'}>
        <CircularProgress color="primary" />
      </Box>
      <Grid
        container
        alignItems="center"
        direction="column"
        component="form"
        onSubmit={loginForm.handleSubmit(handleSubmit)}
        autoComplete="off"
        className="pj-content-section-container  pj-content-section-middle pj-login-phone__form"
      >
        <Grid
          item
        >
          <Controller
            name="phone_number"
            control={loginForm.control}
            rules={{ required: true }}
            render={({ fieldState, field }) => (
              <TextField
                {...field}
                fullWidth
                label="Phone Number"
                variant="standard"
                size="medium"
                color="primary"
                className="pj-light-underline pj-phone-input-holder"
                placeholder="XXXXXXXXX"
                sx={{ maxWidth: '370px;' }}
                InputProps={{
                  inputComponent: PhoneInput,
                  inputProps: {
                    name: field.name
                  }
                }}
                helperText={
                  fieldState.error &&
                  (
                    <Typography
                      variant="p"
                      color="error"
                    >
                      {fieldState.error.message}
                    </Typography>
                  )
                }
              />
            )}
          />

          <Typography
            variant="body2"
            textAlign="center"
            display="block"
            sx={{ marginTop: 0 }}
          >
            if the number below is correct, just click submit
          </Typography>

          <Box className="terms-conditions__container">
            <Controller
              name="termsAndConditions"
              control={loginForm.control}
              rules={{ required: true }}
              placeholder="XXXXXXXXX"
              render={({ field }) => (
                <Checkbox
                  {...field}
                  color="secondary"
                  className="inline-block"
                />
              )}
            />

            <span>
              <Typography
                variant="body2"
                component="p"
                className="inline-block"
              >
                I accept the &nbsp;
                <span
                  className="pj-underline pj-text-primary pointer"
                  onClick={() => setOpenTermsAndConditions(true)}
                >
                  terms and conditions
                </span>
                &nbsp;and{' '}
                <span
                  className="pj-underline pj-text-primary pointer"
                  onClick={openPrivacyPolicyModal}
                >
                  privacy policy
                </span>
              </Typography>
            </span>
          </Box>

          {loginForm.formState.errors.termsAndConditions?.type === 'required' && (
            <Typography color="error">Terms and conditions is required</Typography>
          )}
        </Grid>
        <Box className="pj-content-section-bottom">
          <Button
            type="submit"
            size="large"
            color="secondary"
            disabled={loading}
            fullWidth
            sx={{ maxWidth: '535px' }}
          >
            {loading ? 'Loading...' : 'Submit'}
          </Button>
        </Box>
      </Grid>
      <ModalTerms
        openModal={openTermsAndConditions}
        onClose={setOpenTermsAndConditions}
      />
      <ModalPrivacy
        openModal={openPrivacyPolicy}
        onClose={setOpenPrivacyPolicy}
      />
    </>
  );
};

LoginPhone.defaultProps = {
  openModal: false,
  onSubmit: noop
};

LoginPhone.propTypes = {
  openModal: PropTypes.bool,
  onSubmit: PropTypes.func
};

export default LoginPhone;
