import React from 'react';
import PropTypes from 'prop-types';
import { LayoutProvider } from '../contexts/LayoutContext/LayoutProvider';
import { PICKLEJAR_DEFAULT_BACKGROUND_IMAGE, PICKLEJAR_DEFAULT_LOGO_IMAGE } from '../const/PicklejarTheme';
import { PICKLEJAR_DEFAULT_GO_BACK_PROFILE_PAGE_URL, PICKLEJAR_DEFAULT_PROFILE_PAGE_URL } from '../const/PicklejarSettings';
import UserProfileDetails from './ProfileDetails/UserProfileDetails';

export const PicklejarProfile = props => {
  const { backgroundImage, logoImage, profilePageUrl, goBackUrl, isIDVerification } = props;
  return (
    <LayoutProvider
      backgroundImage={backgroundImage}
      logoImage={logoImage}
    >
      <UserProfileDetails
        profilePageUrl={profilePageUrl}
        returnUrl={goBackUrl}
        isIDVerification={isIDVerification}
      />
    </LayoutProvider>
  );
};

PicklejarProfile.defaultProps = {
  backgroundImage: PICKLEJAR_DEFAULT_BACKGROUND_IMAGE,
  logoImage: PICKLEJAR_DEFAULT_LOGO_IMAGE,
  isIDVerification: false,
  goBackUrl: PICKLEJAR_DEFAULT_GO_BACK_PROFILE_PAGE_URL,
  profilePageUrl: PICKLEJAR_DEFAULT_PROFILE_PAGE_URL
};

PicklejarProfile.propTypes = {
  backgroundImage: PropTypes.string,
  logoImage: PropTypes.string,
  isIDVerification: PropTypes.bool,
  goBackUrl: PropTypes.string,
  profilePageUrl: PropTypes.string
};

export default PicklejarProfile;
