// Packages
import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { get } from 'lodash';
import Chip from '@mui/material/Chip';
import { Box, Button, CircularProgress, Container, Grid, List, ListItem } from '@mui/material';

// Relatives
import http from '../../../services/api/http';
import AppContext from '../../../contexts/AppContext';
import UserContext from '../../../contexts/UserContext';
import NotificationContext from '../../../contexts/NotificationContext';
import { picklejarTextColorDark } from '../../../const/PicklejarTheme';
import GenresModel from '../../../models/GenresModel';


export const UserProfileGenresForm = props => {
  const {
    defaultGenres,
    loading,
    setLoading,
    showInModal,
    skipButtonLabel,
    submitButtonLabel,
    onSubmit,
    customErrorMessage,
    response
  } = props;
  /**
   * App Contexts & Params
   */
  const { apiHost } = useContext(AppContext);
  const { token } = useContext(UserContext);
  const { notificationError } = useContext(NotificationContext);
  /**
   * App State
   */
  const [genres, setGenres] = useState([]);
  const [selected, setSelected] = useState({});
  const userProfileForm = useForm({
    defaultValues: {
      image: null
    }
  });
  const [defaultChipColor, setDefaultChipColor] = useState('white');
  const { setError } = userProfileForm;
  /**
   * Handlers
   */
  const updateSelected = id => {
    const originalSelected = { ...selected };
    if (selected[id]) {
      delete originalSelected[id];
    } else {
      originalSelected[id] = id;
    }
    setSelected(originalSelected);
  };
  const handleSubmit = async () => {
    const formData = new FormData();
    if (Object.keys(selected).length > 0) {
      Object.keys(selected).forEach((genreId, index) => {
        formData.append(`genres[${index}]`, genreId);
      });
      setLoading(true);
      const response = await http(apiHost, token.accessToken, token.tokenType, { 'Content-Type': 'multipart/form-data' })
        .post('/api/web/v1/auth/update-profile', formData)
        .catch(() => {
        });

      const { success, data, error } = get(response, 'data', {});
      let message = '';
      if (!success && data) {
        Object.keys(data).forEach(field => setError(field, { type: 'required', message: get(data, `${field}.0`) }));
        message += 'Please try again.';
      }

      if (!success && error && error.fieldErrors) {
        message += error.fieldErrors[0].message;
      }

      onSubmit(success, data, message);
    } else {
      notificationError(customErrorMessage);
    }
  };
  const skipForm = () => {
    onSubmit(true, response);
  };
  /**
   * Effects
   */
  useEffect(() => {
    if (showInModal) {
      setDefaultChipColor(picklejarTextColorDark);
    }
  }, [showInModal]);
  useEffect(() => {
    if (defaultGenres) {
      defaultGenres.map(genre => setSelected(state => {
        state[genre.id] = genre.id;
        return state;
      }));
    }
  }, [defaultGenres]);
  /**
   * Api Calls
   */
  const fetchGenres = async () => {
    if (setLoading) {
      setLoading(true);
    }

    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v1/music-auxiliar/music-genres`);

    const { success, data } = get(response, 'data', {});

    if (success === true) {
      setGenres(data);
    }

    if (setLoading) {
      setLoading(false);
    }
  };
  // Fetch Genres
  useEffect(() => {
    if (genres.length === 0) {
      fetchGenres().catch(err => console.log(err));
    }
    // TODO: Remove this eslint-disable-next-line
    // eslint-disable-next-line
  }, [genres.length]);

  return (
    <FormProvider {...userProfileForm}>
      <Grid
        container
        component="form"
        onSubmit={userProfileForm.handleSubmit(handleSubmit)}
        autoComplete="off"
        className="pj-user-profile__form"
        maxWidth="md"
      >
        {
          (
          loading && showInModal && genres.length < 1
          ) &&
          (
            <Box sx={{ mt: 5, textAlign: 'center', width: '100%' }}>
              <CircularProgress color="primary" />
            </Box>
          )
        }
        <List>
          <ListItem>
            <Box>
              {
                genres.map(genreItem => {
                  return (
                    <Chip
                      color={selected[genreItem.id] ? 'primary' : 'default'}
                      key={`genre-${genreItem.id}`}
                      label={genreItem.genreName}
                      variant={selected[genreItem.id] ? 'filled' : 'outlined'}
                      sx={{
                        m: .75,
                        color: defaultChipColor,
                        borderColor: selected[genreItem.id] ? picklejarTextColorDark : defaultChipColor,
                        borderWidth: 'thin'
                      }}
                      onClick={() => updateSelected(genreItem.id)}
                    />
                  );
                })
              }
            </Box>
          </ListItem>
          <ListItem>
            {
              showInModal ?
                <Box className="pj-d-flex pj-justify-content-center pj-full-width">
                  <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    disabled={loading === true}
                    fullWidth
                  >
                    {submitButtonLabel}
                  </Button>
                </Box> :
                <Container
                  sx={{
                    justifyContent: 'space-between',
                    mt: 5
                  }}
                  className="pj-user-profile"
                >
                  <Button
                    type="button"
                    variant="text"
                    color="primary"
                    disabled={loading === true}
                    onClick={skipForm}
                  >
                    {skipButtonLabel}
                  </Button>
                  <Button
                    type="submit"
                    variant="outlined"
                    color="primary"
                    disabled={loading === true}
                  >
                    {submitButtonLabel}
                  </Button>
                </Container>
            }
          </ListItem>
        </List>
      </Grid>
    </FormProvider>
  );
};

UserProfileGenresForm.defaultProps = {
  customErrorMessage: 'Please select a genre',
  defaultGenres: null,
  loading: false,
  setLoading: null,
  skipButtonLabel: 'Skip',
  submitButtonLabel: 'Continue',
  showInModal: false,
  skipForm: null,
  response: null
};

UserProfileGenresForm.propTypes = {
  customErrorMessage: PropTypes.string,
  defaultGenres: PropTypes.arrayOf(PropTypes.shape(GenresModel)),
  loading: PropTypes.bool,
  setLoading: PropTypes.func,
  showInModal: PropTypes.bool,
  skipButtonLabel: PropTypes.string,
  submitButtonLabel: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  skipForm: PropTypes.func,
  response: PropTypes.any
};
export default UserProfileGenresForm;
