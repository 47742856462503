import React from 'react';
import { Typography } from '@mui/material';

export const SnackHTMLMessages = messageList => {
  const message = `<strong>Errors found:</strong><ol style="margin: 0; padding-left: 20px;">${messageList}<ol>`;
  return <Typography
    paragraph={false}
    dangerouslySetInnerHTML={{ __html: message }}
  />;
};
