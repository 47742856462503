import React, { useContext, useEffect } from 'react';
import { IconButton } from '@mui/material';
import { SnackbarProvider, useSnackbar } from 'notistack';
import CloseIcon from '@mui/icons-material/Close';
import { isNull } from 'lodash';
// Relatives
import NotificationContext from '../../../contexts/NotificationContext';

const CloseButton = key => {
  const { closeSnackbar } = useSnackbar();
  return (
    <IconButton
      aria-label="Close notification"
      color="inherit"
      onClick={() => closeSnackbar(key)}
    >
      <CloseIcon fontSize="small" />
    </IconButton>
  );
};
export const Notification = () => {
  /**
   * App Contexts & Params
   */
  const { notification, notificationText } = useContext(NotificationContext);
  const { enqueueSnackbar } = useSnackbar();
  /**
   * Effects
   */
  useEffect(() => {
    if (!isNull(notification)) {
      enqueueSnackbar(notificationText, { variant: notification });
    }
  }, [notification]);
};

export const NotificationBar = () => (
  <SnackbarProvider
    maxSnack={5}
    autoHideDuration={5000}
    preventDuplicate
    disableWindowBlurListener
    action={() => CloseButton()}
  >
    <Notification />
  </SnackbarProvider>
);

export default NotificationBar;
