import React, { useContext } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container, Grid } from '@mui/material';
import classNames from 'classnames';
import PropTypes from 'prop-types';

// Relatives
import LayoutContext from '../../contexts/LayoutContext';
import { PICKLEJAR_DEFAULT_BACKGROUND_IMAGE, PICKLEJAR_DEFAULT_LOGO_IMAGE } from '../../const/PicklejarTheme';

export const LoginLayout = props => {
  const { children, backgroundImage, logoImage } = props;
  const {
    picklejarLogo,
    picklejarBackgroundImage,
    header,
    subtitle,
    pageTitle,
    pageClass,
    hideLogo,
    wrapperClassName
  } = useContext(LayoutContext);
  return (
    <>
      <Helmet>
        <title>{`PickleJar - ${pageTitle}`}</title>
      </Helmet>
      <Box className={classNames('pj-account', { [wrapperClassName]: wrapperClassName, [pageClass]: pageClass })}>
        <picture className="pj-fill-background">
          <img
            src={picklejarBackgroundImage || backgroundImage}
            alt="background img"
          />
        </picture>
        <Container
          maxWidth="lg"
          className="pj-content-section-container"
        >
          <Grid
            container
            direction="column"
            className="pj-content-section-top"
          >
            <Box className="pj-content-section-top-container">
              {
                hideLogo === false &&
                (
                  <Box className="pj-brand-logo">
                    <img
                      src={picklejarLogo || logoImage}
                      alt="picklejar logo"
                    />
                  </Box>
                )
              }
              {header}
            </Box>
            {subtitle}
          </Grid>
          {children}
        </Container>
      </Box>
    </>
  );
};

LoginLayout.defaultProps = {
  backgroundImage: PICKLEJAR_DEFAULT_BACKGROUND_IMAGE,
  logoImage: PICKLEJAR_DEFAULT_LOGO_IMAGE,
  children: null
};

LoginLayout.propTypes = {
  backgroundImage: PropTypes.string,
  logoImage: PropTypes.string,
  children: PropTypes.node
};

