import React from 'react';
import PropTypes from 'prop-types';
import { LayoutProvider } from '../contexts/LayoutContext/LayoutProvider';
import { PICKLEJAR_DEFAULT_BACKGROUND_IMAGE, PICKLEJAR_DEFAULT_LOGO_IMAGE } from '../const/PicklejarTheme';
import { PICKLEJAR_DEFAULT_GO_BACK_PROFILE_PAGE_URL, PICKLEJAR_DEFAULT_PROFILE_PAGE_URL } from '../const/PicklejarSettings';
import { IDVerificationProvider } from '../contexts/IDVerificationContext/IDVerificationProvider';
import IDVerification from './IDVerification/IDVerification';

export const PicklejarIDVerification = props => {
  const { backgroundImage, logoImage, redirectOnSuccess, goBackUrl } = props;
  return (
    <LayoutProvider
      backgroundImage={backgroundImage}
      logoImage={logoImage}
    >
      <IDVerificationProvider>
        <IDVerification
          redirectOnSuccess={redirectOnSuccess}
          returnUrl={goBackUrl}
        />
      </IDVerificationProvider>
    </LayoutProvider>
  );
};

PicklejarIDVerification.defaultProps = {
  backgroundImage: PICKLEJAR_DEFAULT_BACKGROUND_IMAGE,
  logoImage: PICKLEJAR_DEFAULT_LOGO_IMAGE,
  redirectOnSuccess: PICKLEJAR_DEFAULT_PROFILE_PAGE_URL,
  goBackUrl: PICKLEJAR_DEFAULT_GO_BACK_PROFILE_PAGE_URL
};

PicklejarIDVerification.propTypes = {
  backgroundImage: PropTypes.string,
  logoImage: PropTypes.string,
  redirectOnSuccess: PropTypes.string,
  goBackUrl: PropTypes.string
};

export default PicklejarIDVerification;
