/* eslint-disable camelcase */
// Packages
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Box, Button, CircularProgress, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { useNavigate } from 'react-router-dom';
import get from 'lodash/get';
import { ChevronLeft } from '@mui/icons-material';

// Relatives
import http from '../../services/api/http';
import AppContext from '../../contexts/AppContext';
import UserContext from '../../contexts/UserContext';
import NotificationContext from '../../contexts/NotificationContext';
import LayoutContext from '../../contexts/LayoutContext';
import IDVerificationContext from '../../contexts/IDVerificationContext';
import LoginLayout from '../../layouts/Login';
import { PICKLEJAR_DEFAULT_GO_BACK_PROFILE_PAGE_URL, PICKLEJAR_DEFAULT_PROFILE_PAGE_URL } from '../../const/PicklejarSettings';
import IDVerificationStep from './IDVerificationSteps/IDVerificationStep';
import IDVerificationStep3 from './IDVerificationSteps/IDVerificationStep3';
import IDVerificationStep4 from './IDVerificationSteps/IDVerificationStep4';


const IDVerification = props => {
  const { redirectUrl } = props;
  /**
   * App Contexts & Params
   */
  const { apiHost } = useContext(AppContext);
  const { token, getUser } = useContext(UserContext);
  const { notificationSuccess, notificationError } = useContext(NotificationContext);
  const {
    setHeader,
    setHideLogo,
    setSubtitle,
    setPageClass,
    setPageTitle
  } = useContext(LayoutContext);
  const {
    setSupportTicket,
    setIDVerificationBackImageId,
    setIDVerificationFrontImageId,
    setDefaultBackIDPreviewUrl,
    setDefaultFrontIDPreviewUrl,
    setIDVerificationVideoId,
    setDefaultVideoPreviewUrl
  } = useContext(IDVerificationContext);
  /**
   * App State
   */
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [response, setResponse] = useState({});
  /**
   * Handlers
   */
  const nextStep = (success, data, message = '') => {
    setLoading(false);

    if (data) {
      setResponse(data);
    }

    if (success) {
      if (activeStep < steps.length - 1) {
        setActiveStep(state => state + 1);
      } else {
        message = 'User information successfully updated';
        navigate(redirectUrl);
      }
    }

    if (message) {
      if (success) {
        notificationSuccess(message);
      } else {
        notificationError(message);
      }
    }
  };
  const prevStep = () => {
    if (activeStep === 0) {
      navigate(redirectUrl);
      return;
    }
    setActiveStep(state => state - 1);
  };
  const steps = [
    {
      label: 'Take a photo of the front and back of your ID',
      description: null,
      content: <IDVerificationStep
        activeStep={activeStep}
        loading={loading}
        nextStep={nextStep}
        onSubmit={nextStep}
        setLoading={setLoading}
        submitUrl="/api/web/v1/auth/upload-profile-picture"
      />
    },
    {
      label: 'Take a photo of the front and back of your ID',
      description: null,
      content: <IDVerificationStep
        activeStep={activeStep}
        loading={loading}
        nextStep={nextStep}
        onSubmit={nextStep}
        setLoading={setLoading}
        submitUrl="/api/web/v1/auth/upload-profile-picture"
        takePictureButtonLabel="Take a photo of back ID"
        uploadPictureButtonLabel="Upload a photo of back ID"
      />
    },
    {
      label: 'Take a video of You holding your ID',
      description: null,
      content: <IDVerificationStep3
        loading={loading}
        nextStep={nextStep}
        onSubmit={nextStep}
        setLoading={setLoading}
        submitUrl="/api/web/v1/auth/upload-profile-picture"
      />
    },
    {
      label: 'Send us a message',
      description: '(details just verifying you are the user)',
      content: <IDVerificationStep4
        loading={loading}
        onSubmit={nextStep}
        response={response}
        setLoading={setLoading}
      />
    }
  ];
  /**
   * API Requests
   */
  const createIDVerificationSupportTicket = async owner_class => {
    const owner_id = get(getUser(), 'id', '');
    const supportTicketResponse = await http(apiHost, token.accessToken, token.tokenType)
      .post('/api/web/v2/manual-entity-verification', {
        owner_class,
        owner_id
      })
      .catch(error => {
        console.log(error);
        notificationError('An error occurred while creating a support ticket');
      });

    if (!supportTicketResponse || !supportTicketResponse.data) {
      return;
    }

    const { success, error, message, output } = get(supportTicketResponse, 'data', {});
    if (!success && error) {
      if (error.message) {
        notificationError(error.message);
        return;
      }
    }

    if (success === true) {
      setSupportTicket(output);
      notificationSuccess('Support ticket successfully created');
    } else {
      notificationError(message);
    }
  };
  const loadEntityList = async () => {
    if (!token) {
      return;
    }

    setLoading(true);
    const getEntities = await http(apiHost, token.accessToken, token.tokenType)
      .get('/api/web/v2/helpers/available-entities/list')
      .catch(err => {
        console.log(err);
        notificationError('An error occurred obtaining available entities, please contact support.');
      });

    if (!getEntities || !getEntities.data) {
      setLoading(false);
      return;
    }

    const { success, output, error } = get(getEntities, 'data', {});

    if (!success && error) {
      console.log(error);
      if (error.message) {
        notificationError(error.message);
      }
    } else {
      const userEntity = output.find(({ name }) => {
        return name.toLowerCase() === 'users';
      });
      if (userEntity) {
        createIDVerificationSupportTicket(userEntity.class);
      } else {
        notificationError('Valid entity not found');
      }

    }
  };
  const getVerificationIdTicketList = useCallback(async () => {
    if (!token) {
      return;
    }

    setLoading(true);
    const ticketList = await http(apiHost, token.accessToken, token.tokenType)
      .get('/api/web/v2/manual-entity-verification')
      .catch(err => {
        console.log(err);
        notificationError('An error occurred validating your profile, please contact support.');
      });

    if (!ticketList || !ticketList.data) {
      setLoading(false);
      return;
    }

    const { success, output, error } = get(ticketList, 'data', {});

    if (!success && error) {
      console.log(error);
      if (error.message) {
        notificationError(error.message);
      }
    } else {
      const data = get(output, 'data', null);
      if (data) {
        const ticket = data.filter(supportTicket => {
          const relatedTicket = get(supportTicket, '_related.ticket', null);
          return relatedTicket && supportTicket.owner_id === getUser().id;
        });
        if (ticket.length === 0) {
          loadEntityList().finally(() => setLoading(false));
        } else {
          const supportTicket = ticket[0];
          setSupportTicket(supportTicket);
          const attachments = get(supportTicket, '_related.ticket._misc.attachments', []);
          const backList = [];
          const frontList = [];
          const videoList = [];
          const itemsData = [
            { arrayData: backList, fileName: 'backid', callbacks: [setIDVerificationBackImageId, setDefaultBackIDPreviewUrl] },
            { arrayData: frontList, fileName: 'frontid', callbacks: [setIDVerificationFrontImageId, setDefaultFrontIDPreviewUrl] },
            { arrayData: videoList, fileName: 'videoidverification', callbacks: [setIDVerificationVideoId, setDefaultVideoPreviewUrl] }
          ];

          const generateImageList = (arrayData, attachment, fileName) => {
            const title = get(attachment, '_misc.media.title', null);
            const url = get(attachment, '_misc.media.url', null);

            if (title && url) {
              if (title.includes(fileName)) {
                arrayData.push({ id: attachment.id, url });
              }
            }
          };

          attachments.forEach(attachment => {
            itemsData.forEach(({ arrayData, fileName }) => {
              generateImageList(arrayData, attachment, fileName);
            });
          });

          itemsData.forEach(({ arrayData, callbacks }) => {
            if (arrayData.length > 0) {
              const file = arrayData[arrayData.length - 1];
              callbacks[0](file.id);
              callbacks[1](file.url);
            }
          });
        }
      } else {
        notificationError('Valid entity not found');
      }
    }
  }, []);
  /**
   * Effects
   */
  useEffect(() => {
    getVerificationIdTicketList();
  }, [getVerificationIdTicketList]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    setHideLogo(true);
    setHeader(
      <>
        <Grid container>
          <Grid
            item
            xs={6}
            textAlign="start"
          >
            <Button
              type="button"
              onClick={prevStep}
              color="primary"
              size="large"
              variant="text"
              startIcon={<ChevronLeft fontSize="large" />}
              disabled={loading}
              sx={{ mt: { md: 5, sm: 1 }, mb: { md: 3, sm: 2 } }}
            />
          </Grid>
          <Grid
            item
            xs={6}
            textAlign="end"
          >
            <Button
              type="button"
              onClick={() => navigate(PICKLEJAR_DEFAULT_GO_BACK_PROFILE_PAGE_URL)}
              color="primary"
              size="large"
              variant="text"
              disabled={loading}
              sx={{ mt: { md: 5, sm: 1 }, mb: { md: 3, sm: 2 } }}
            >Skip</Button>
          </Grid>
        </Grid>
        <Typography
          color="primary"
          variant="h1"
          className="pj-login-header"
          dangerouslySetInnerHTML={{ __html: steps[activeStep].label }}
        />
      </>
    );
    setSubtitle(
      <Typography
        variant="body2"
        className="pj-login-subtitle"
        dangerouslySetInnerHTML={{ __html: steps[activeStep].description }}
      />
    );
    setPageTitle('Verify Your Id');
    setPageClass('IDVerification');
    // TODO: eslint-disable-next-line react-hooks/exhaustive-deps

  }, [activeStep, setHideLogo, setHeader, setSubtitle, setPageTitle, setPageClass]);
  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return (
    <LoginLayout>
      <Box className={loading ? 'loading-overlay' : 'loading-overlay hidden'}>
        <CircularProgress color="primary" />
      </Box>
      {steps[activeStep].content}
    </LoginLayout>
  );
};

IDVerification.defaultProps = {
  onSubmit: noop,
  onCancel: noop,
  id: null,
  updateMode: false,
  redirectUrl: PICKLEJAR_DEFAULT_PROFILE_PAGE_URL
};

IDVerification.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  id: PropTypes.number,
  updateMode: PropTypes.bool,
  redirectUrl: PropTypes.string
};

export default IDVerification;
