/* eslint-disable camelcase */
// Packages
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, Container, Grid, IconButton, LinearProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Webcam from 'react-webcam';

// Relatives
import get from 'lodash/get';
import DialogTitle from '@mui/material/DialogTitle';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import CloseIcon from '@mui/icons-material/Close';
import { divide, isEmpty, isEqual, isNil, isNull, multiply, round } from 'lodash';
import IDVerificationContext from '../../../contexts/IDVerificationContext';
import { UploadImage, UploadSupportAttachment } from '../../../services/api/UploadImage';
import AppContext from '../../../contexts/AppContext';
import UserContext from '../../../contexts/UserContext';
import NotificationContext from '../../../contexts/NotificationContext';
import { FACING_MODE_ENVIRONMENT, FACING_MODE_USER, PICKLEJAR_VIDEO_CONSTRAINS } from '../../../const/PicklejarTheme';


export const IDVerificationStep3 = props => {
  const {
    loading,
    nextStep,
    onSubmit,
    submitUrl,
    setLoading,
    takeVideoButtonLabel
  } = props;
  const { apiHost } = useContext(AppContext);
  const { token } = useContext(UserContext);
  /**
   * App Contexts & Params
   */
  const { notificationError } = useContext(NotificationContext);
  const {
    supportTicket,
    defaultVideoPreviewUrl,
    iDVerificationVideoId,
    setIDVerificationVideoId,
    setDefaultVideoPreviewUrl
  } = useContext(IDVerificationContext);
  /**
   * App State
   */
  const inputId = 'IDVerificationFrontImage';
  const label = useRef(null);
  const recordingTime = 5;
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [previewCapture, setPreviewCapture] = useState(null);
  const [openTakePictureModal, setOpenTakePictureModal] = useState(false);
  const [showPreviewCapture, setShowPreviewCapture] = useState(false);
  // Video Handlers
  const [mediaRecorderOptions, setMediaRecorderOptions] = useState({});
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const videoRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [videoBlob, setVideoBlob] = useState(null);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [videoSrc, setVideoSrc] = useState('');
  const [disableCapture, setDisableCapture] = useState(true);
  const [counter, setCounter] = useState(null);
  const [counterTimeout, setCounterTimeout] = useState(undefined);
  const [progress, setProgress] = useState(0);
  const [alertMessage, setAlertMessage] = useState('');
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [msgUploadProgressBanner, setMsgUploadProgressBanner] = useState({ progress: null, color: 'primary' });
  const [facingMode, setFacingMode] = useState(FACING_MODE_ENVIRONMENT);
  const [deviceId, setDeviceId] = React.useState({});
  const [devices, setDevices] = React.useState([]);
  /**
   *
   * Handlers
   */
  const handleDevices = React.useCallback(
    mediaDevices => setDevices(mediaDevices.filter(({ kind }) => kind === 'videoinput')),
    [setDevices]
  );
  const triggerOpenTakePictureModal = () => {
    setOpenTakePictureModal(true);
  };
  const resetCounter = () => {
    if (counterTimeout) {
      clearTimeout(counterTimeout);
    }
    setCounter(null);
    setCounterTimeout(null);
    setProgress(0);
  };
  const triggerCloseAlertModal = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    setOpenAlertModal(false);
  };
  const enableCapture = MediaStream => {
    if (MediaStream.active) {
      setTimeout(() => {
        setDisableCapture(false);
      }, 1500);
    }
  };
  const retryTakeVideo = () => {
    setPreviewCapture(null);
    setShowPreviewCapture(false);
    setRecordedChunks([]);
    resetCounter();
  };
  const confirmVideo = () => {
    loadURLToInputFiled();
  };
  const triggerNextStep = (fileData, success, data, message) => {
    onSubmit(success, data, message);
  };
  const triggerContinue = () => {
    nextStep(true, null, null);
  };
  // xmlHTTP return blob respond
  const loadURLToInputFiled = () => {
    const fileName = `videoIdVerification${mediaRecorderOptions.extension}`;
    const file = new File(
      [videoBlob],
      fileName,
      {
        type: mediaRecorderOptions.mimeType,
        lastModified: new Date().getTime()
      },
      'utf-8'
    );
    const container = new DataTransfer();
    container.items.add(file);
    const fileData = {
      croppedImageUrl: previewCapture,
      file: container.files[0],
      originalFile: container.files[0],
      originalSrc: previewCapture
    };
    updateIDVerificationPicture(setLoadingAvatar, 'image', fileData).then();
  };
  /**
   * Handlers
   */
  const triggerCloseTakePictureModal = (event, reason) => {

    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    setOpenTakePictureModal(false);
    setShowPreviewCapture(false);
    setDisableCapture(true);
    setCapturing(false);
    resetCounter();
  };
  const handleBackdropClick = event => {
    // these fail to keep the modal open
    event.stopPropagation();
    return false;
  };
  const handleStopCaptureClick = useCallback(() => {
    if (
      mediaRecorderRef.current &&
      (
        mediaRecorderRef.current.state !== 'inactive' || mediaRecorderRef.current.state === 'recording'
      )
    ) {
      mediaRecorderRef.current.stop();
      setCapturing(false);
      setShowPreviewCapture(true);
    }
  }, [mediaRecorderRef, setCapturing]);
  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks(prev => {
          return prev.concat(data);
        });
      }
    },
    [setRecordedChunks]
  );
  const handleStartCaptureClick = useCallback(() => {
    setCounter(null);
    try {
      setCapturing(true);
      if (MediaRecorder.isTypeSupported('video/webm; codecs=vp9')) {
        setMediaRecorderOptions({ mimeType: 'video/webm; codecs=vp9', extension: '.webm' });
      } else if (MediaRecorder.isTypeSupported('video/webm')) {
        setMediaRecorderOptions({ mimeType: 'video/webm', extension: '.webm' });
      } else if (MediaRecorder.isTypeSupported('video/mp4')) {
        setMediaRecorderOptions({ mimeType: 'video/mp4', extension: '.mp4' });
      } else {
        console.error('no suitable mimetype found for this device');
        notificationError('no suitable mimetype found for this device');
        setCapturing(false);
        return;
      }

      mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, mediaRecorderOptions);
      mediaRecorderRef.current.addEventListener(
        'dataavailable',
        handleDataAvailable
      );
      mediaRecorderRef.current.start();
      setCounter(recordingTime);
    } catch (e) {
      console.log(e);
      notificationError('Error occurred starting video capture. Please Try Again');
    }

  }, [setCounter, setCapturing, mediaRecorderRef, handleDataAvailable, notificationError]);
  const uploadProgressBanner = data => {
    const messageUploadOnProgress = round(multiply(divide(data.loaded, data.total), 100));
    const messageUploadEnd = 100;

    if (isEqual(data.loaded, data.total)) {
      setMsgUploadProgressBanner({ progress: messageUploadEnd, color: 'success' });

      setTimeout(() => {
        setMsgUploadProgressBanner({ progress: null, color: 'dark' });
      }, 3000);

      return messageUploadEnd;
    }

    if (!isEqual(data.loaded, data.total)) {
      setMsgUploadProgressBanner({ progress: messageUploadOnProgress, color: 'warning' });
      return messageUploadOnProgress;
    }


    return false;
  };

  const switchCamera = React.useCallback(() => {
    setFacingMode(
      prevState => prevState === FACING_MODE_USER ?
        FACING_MODE_ENVIRONMENT :
        FACING_MODE_USER
    );
  }, [facingMode]);

  /**
   * API Requests
   */
  const triggerIsLoading = loadingStatus => {
    setLoading(loadingStatus);
    setLoadingAvatar(loadingStatus);
    setOpenAlertModal(loadingStatus);
  };
  const triggerUploadAttachment = async media_id => {
    setAlertMessage('Updating request...');
    const ticket_head_id = get(supportTicket, '_related.ticket.id');
    const updateAttachmentResponse = await UploadSupportAttachment(
      apiHost,
      token.accessToken,
      token.tokenType,
      uploadProgressBanner,
      ticket_head_id,
      null,
      media_id
    );
    const data = get(updateAttachmentResponse, 'data', {});
    const success = get(data, 'success', false);
    if (!success) {
      notificationError('Something went wrong. please try again');
    }
    return data;
  };
  const updateIDVerificationPicture = async (callbackLoading, input, fileData) => {
    if (!token) {
      notificationError('Missing validation token');
      return;
    }

    if (!submitUrl) {
      notificationError('Missing url parameter');
      return;
    }

    setLoadingAvatar(true);
    setAlertMessage('Uploading video...');
    triggerIsLoading(true);
    triggerCloseTakePictureModal();
    const response = await UploadImage(apiHost, token.accessToken, token.tokenType, fileData, uploadProgressBanner);
    const { success, data } = get(response, 'data', {});

    setLoadingAvatar(false);
    if (!response || !data) {
      notificationError('Something went wrong. please try again');
      triggerIsLoading(false);
      return;
    }

    if (success === true) {
      let errorMessage = '';
      if (data) {
        if (data.files) {
          data.files.forEach(file => {
            if (file.error) {
              errorMessage += file.error;
            }
          });

          if (!isNil(errorMessage)) {
            notificationError(errorMessage);
            return;
          }
        }
        if (data.length && data[0].id) {
          setDefaultVideoPreviewUrl(videoSrc);
          setIDVerificationVideoId(data[0].id);
          triggerUploadAttachment(data[0].id).then(request => {
            if (request.success) {
              triggerNextStep(fileData, request.success, request.output, 'Video saved successfully');
            }
          });
        }
      }
    } else if (!data) {
      notificationError('Something went wrong. please try again');
      triggerIsLoading(false);
    }
  };
  /**
   * useCallbacks Functions
   */
  const updateRecordedChunks = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: mediaRecorderOptions.mimeType,
        lastModified: Date.now(),
        name: `test.${mediaRecorderOptions.mimeType}`
      });

      const blobUrl = URL.createObjectURL(blob);
      setVideoBlob(blob);
      setVideoSrc(blobUrl);
      setPreviewCapture(blobUrl);
    }
  }, [setVideoBlob, setVideoSrc, setPreviewCapture, recordedChunks]);
  /**
   * Effects
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    updateRecordedChunks(recordedChunks);
  }, [updateRecordedChunks, recordedChunks]);
  useEffect(() => {
    videoRef.current?.load();
  }, [videoSrc]);
  useEffect(() => {
    const updateCounter = () => {
      if (counter > 0) {
        const timeout = setTimeout(() => setCounter(prev => prev - 1), 1000);
        if (isNull(counterTimeout)) {
          setCounterTimeout(timeout);
        }
        setProgress(prev => prev + (
          100 / recordingTime
        ));
        return;
      }

      if (capturing === true) {
        handleStopCaptureClick();
      }
    };

    updateCounter();
  }, [counter, capturing, handleStopCaptureClick]);
  useEffect(() => {
    if (!isNil(msgUploadProgressBanner.progress)) {
      setLoading(true);
    }

    if (isNil(msgUploadProgressBanner.progress)) {
      setLoading(false);
    }
  }, [msgUploadProgressBanner.progress, setLoading]);
  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then(handleDevices);
  }, [handleDevices]);
  useEffect(() => {
    setDeviceId(webcamRef?.current?.stream?.id);
  }, [deviceId, webcamRef.current]);
  return (
    <Container
      className="pj-user-profile__form"
      maxWidth="md"
    >
      <Grid container>
        <Grid
          item
          paddingLeft={2}
          paddingRight={2}
          marginRight="auto"
          marginLeft="auto"
          md={5}
          sm={6}
          xs={12}
        >
          {
            defaultVideoPreviewUrl.includes('blob:') || !isNil(iDVerificationVideoId) ?
              <video
                width="100%"
                height="auto"
                controls
                ref={videoRef}
                className="video-input border-rounded"
                src={defaultVideoPreviewUrl}
              >
                <source src={defaultVideoPreviewUrl} />
                <track kind="captions" />
              </video> :
              <img
                className="image-crop-input border-rounded"
                alt="selfie video"
                src={defaultVideoPreviewUrl}
              />
          }

        </Grid>
        <Grid
          item
          paddingLeft={2}
          paddingRight={2}
          xs={12}
        >
          <Grid container>
            <Grid
              item
              xs={12}
            >
              {
                loadingAvatar ?
                  <CircularProgress
                    color="primary"
                    size={24}
                  /> :
                  <>
                    {
                      iDVerificationVideoId &&
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        sx={{ mt: 4 }}
                        onClick={triggerContinue}
                      > Continue </Button>
                    }
                    <Button
                      color="primary"
                      onClick={triggerOpenTakePictureModal}
                      disabled={loading || loadingAvatar}
                      fullWidth
                      sx={{ my: 2 }}
                    >
                      {takeVideoButtonLabel}
                    </Button>
                  </>
              }
              <label
                htmlFor={inputId}
                ref={label}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        onClose={triggerCloseTakePictureModal}
        open={openTakePictureModal}
        maxWidth="sm"
        fullWidth
        disableEscapeKeyDown
        onBackdropClick={handleBackdropClick}
      >
        <DialogTitle>
          {takeVideoButtonLabel}
          <IconButton
            aria-label="close"
            onClick={triggerCloseTakePictureModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              width: 48,
              height: 48
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Box>
          {showPreviewCapture ?
            <>
              {
                !isEmpty(videoSrc) &&
                <video
                  controls
                  ref={videoRef}
                  src={videoSrc}
                >
                  <source src={videoSrc} />
                  <track kind="captions" />
                </video>
              }
              <DialogActions sx={{ justifyContent: 'center', p: 2 }}>
                <Button
                  fullWidth
                  variant="outlined"
                  onClick={retryTakeVideo}
                >
                  Retry
                </Button>

                <Button
                  fullWidth
                  variant="contained"
                  onClick={confirmVideo}
                >
                  Confirm
                </Button>
              </DialogActions>
            </> :
            <>
              {
                disableCapture &&
                <Typography
                  variant="body1"
                  sx={{
                    textAlign: 'center',
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    zIndex: 0
                  }}
                >
                  <CircularProgress
                    color="primary"
                    size={18}
                  /> Accessing to your camera to take a video
                </Typography>
              }
              <Webcam
                audio={false}
                ref={webcamRef}
                onUserMedia={enableCapture}
                screenshotQuality={1}
                videoConstraints={{
                  ...PICKLEJAR_VIDEO_CONSTRAINS,
                  facingMode,
                  deviceId
                }}
                style={{ zIndex: 1 }}
              />
              <LinearProgress
                variant="determinate"
                value={progress}
              />
              <DialogActions sx={{ justifyContent: 'center', my: 1 }}>
                {capturing ? (
                  <Button
                    fullWidth
                    variant="contained"
                    color="primary"
                    onClick={handleStopCaptureClick}
                  >
                    {counter && `(${counter - 1})`} Stop Capture
                  </Button>
                ) : (
                  <>
                    {
                      devices.length > 1 &&
                      <Button
                        fullWidth
                        variant="contained"
                        color="secondary"
                        onClick={switchCamera}
                        disabled={disableCapture}
                      >
                        Switch Camera
                      </Button>
                    }
                    <Button
                      fullWidth
                      variant="contained"
                      color="primary"
                      onClick={handleStartCaptureClick}
                      disabled={disableCapture === true}
                    >
                      Start Capture
                    </Button>
                  </>
                )}
              </DialogActions>
            </>
          }
        </Box>
      </Dialog>

      <Dialog
        onClose={triggerCloseAlertModal}
        open={openAlertModal}
        fullWidth
        disableEscapeKeyDown
        onBackdropClick={handleBackdropClick}
      >
        <DialogTitle>Please Wait...</DialogTitle>
        <Typography
          paragraph={false}
          variant="body1"
          sx={{
            textAlign: 'center',
            zIndex: 0,
            my: 4
          }}
        >
          <CircularProgress
            color="primary"
            size={18}
          /> {alertMessage}
        </Typography>
        <Box sx={{ my: 2, px: 4 }}>
          {
            !isNil(msgUploadProgressBanner.progress) &&
            <LinearProgress
              className="banner__progress-overlay"
              value={msgUploadProgressBanner.progress}
              color="primary"
            >
              <span className="text-dark">{msgUploadProgressBanner.progress}%</span>
            </LinearProgress>
          }
        </Box>
      </Dialog>
    </Container>
  );
};

IDVerificationStep3.defaultProps = {
  takeVideoButtonLabel: 'Take Video'
};

IDVerificationStep3.propTypes = {
  loading: PropTypes.bool.isRequired,
  nextStep: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  submitUrl: PropTypes.string.isRequired,
  takeVideoButtonLabel: PropTypes.string
};

export default IDVerificationStep3;
