// Packages
import React, { useMemo, useState } from 'react';
import PropTypes from 'prop-types';

// Relatives
import IDVerificationContext from './IDVerificationContext';
import { PICKLEJAR_DEFAULT_ID_VALIDATION_BACK_IMAGE, PICKLEJAR_DEFAULT_ID_VALIDATION_FRONT_IMAGE, PICKLEJAR_DEFAULT_ID_VALIDATION_VIDEO_IMAGE } from '../../pages/IDVerification';


export const IDVerificationProvider = props => {
  const { children, backIDPreviewUrl, frontIDPreviewUrl, videoIDPreviewUrl } = props;
  /**
   * App State
   */
  const [defaultBackIDPreviewUrl, setDefaultBackIDPreviewUrl] =
    useState(backIDPreviewUrl || PICKLEJAR_DEFAULT_ID_VALIDATION_BACK_IMAGE);
  const [defaultFrontIDPreviewUrl, setDefaultFrontIDPreviewUrl] =
    useState(frontIDPreviewUrl || PICKLEJAR_DEFAULT_ID_VALIDATION_FRONT_IMAGE);
  const [defaultVideoPreviewUrl, setDefaultVideoPreviewUrl] =
    useState(videoIDPreviewUrl || PICKLEJAR_DEFAULT_ID_VALIDATION_VIDEO_IMAGE);
  const [iDVerificationFrontImageId, setIDVerificationFrontImageId] = useState(null);
  const [iDVerificationBackImageId, setIDVerificationBackImageId] = useState(null);
  const [iDVerificationVideoId, setIDVerificationVideoId] = useState(null);
  const [supportTicket, setSupportTicket] = useState(null);
  const IDVerificationValueMemo = useMemo(
    () => (
      {
        defaultBackIDPreviewUrl,
        defaultFrontIDPreviewUrl,
        defaultVideoPreviewUrl,
        iDVerificationFrontImageId,
        iDVerificationBackImageId,
        iDVerificationVideoId,
        supportTicket,
        setDefaultBackIDPreviewUrl,
        setDefaultFrontIDPreviewUrl,
        setDefaultVideoPreviewUrl,
        setIDVerificationFrontImageId,
        setIDVerificationBackImageId,
        setIDVerificationVideoId,
        setSupportTicket
      }
    ),
    [
      defaultBackIDPreviewUrl,
      defaultFrontIDPreviewUrl,
      defaultVideoPreviewUrl,
      iDVerificationFrontImageId,
      iDVerificationBackImageId,
      iDVerificationVideoId,
      supportTicket,
      setDefaultBackIDPreviewUrl,
      setDefaultFrontIDPreviewUrl,
      setDefaultVideoPreviewUrl,
      setIDVerificationFrontImageId,
      setIDVerificationBackImageId,
      setIDVerificationVideoId,
      setSupportTicket
    ]
  );
  return (
    <IDVerificationContext.Provider value={IDVerificationValueMemo}>{children}</IDVerificationContext.Provider>
  );
};

IDVerificationProvider.defaultProps = {
  children: null,
  defaultBackIDPreviewUrl: null,
  defaultFrontIDPreviewUrl: null,
  backIDPreviewUrl: null,
  frontIDPreviewUrl: null,
  videoIDPreviewUrl: null
};

IDVerificationProvider.propTypes = {
  children: PropTypes.node,
  defaultBackIDPreviewUrl: PropTypes.string,
  defaultFrontIDPreviewUrl: PropTypes.string,
  backIDPreviewUrl: PropTypes.string,
  frontIDPreviewUrl: PropTypes.string,
  videoIDPreviewUrl: PropTypes.string
};
