/* eslint-disable camelcase */
// Packages
import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Navigate } from 'react-router';
import { Box, CircularProgress, Grid, Typography } from '@mui/material';
import { isNil } from 'lodash';
import get from 'lodash/get';
// Relatives
import UserContext from '../../contexts/UserContext/UserContext';
import AppContext from '../../contexts/AppContext';
import http from '../../services/api/http';
import { LoginLayout } from '../../layouts/Login/LoginLayout';
import VerifySms from './steps/VerifySms';
import LoginPhone from './steps/LoginPhone';
import UserProfileSetup from './steps/UserProfileSetup';
import LayoutContext from '../../contexts/LayoutContext';
import { PICKLEJAR_DEFAULT_ID_VERIFY_PAGE_URL } from '../../const/PicklejarSettings';

const Login = props => {
  const { loginRedirect } = props;
  const queryParams = new URLSearchParams(window.location.search);
  const referenceLinkToken = queryParams.get('link_at');
  const documentReferer = document.referrer;
  /**
   * App Contexts & Params
   */
  const {
    setUser,
    getUser,
    setUserParams,
    setUserStatus,
    logout,
    setCanEditProfile
  } = useContext(UserContext);
  const {
    setHeader,
    setSubtitle,
    setPageClass
  } = useContext(LayoutContext);
  const { apiHost } = useContext(AppContext);
  /**
   * App State
   */
  const [mobilePhone, setMobilePhone] = useState('');
  const [phase, setPhase] = useState(() => {
    const user = getUser();

    if (!isNil(referenceLinkToken)) {
      return 'phase0';
    }

    if (!user) {
      return 'phase1';
    }

    const { status } = user;

    if (status === 'inactive') {
      return 'phase3';
    }

    return 'phase4';
  });
  const [confirmationToken, setConfirmationToken] = useState('');
  const [userVerifiedStatus, setUserVerifiedStatus] = useState(get(getUser(), 'params.userVerifiedStatus', ''));
  const navigate = useNavigate();

  const loginPhase1 = async data => {
    if (!data) {
      return;
    }


    const { mobilePhone, confirmationToken } = data;
    setMobilePhone(mobilePhone);
    setUserParams({ mobilePhone });
    setConfirmationToken(confirmationToken);
    setPhase('phase2');
  };

  const loginPhase2 = async data => {
    if (!data) {
      return;
    }

    let {
      id,
      // eslint-disable-next-line prefer-const
      token,
      userName,
      profile,
      status,
      proveVerified,
      proveEligibility,
      user_verified_status
    } = data;
    if (status === 'inactive') {
      setUser({
        id,
        token,
        status,
        params: {
          userName,
          profile,
          mobilePhone,
          userVerifyStatus: user_verified_status
        },
        proveVerified,
        proveEligibility
      });
      setPhase('phase3');
    } else {
      const response = await http(apiHost, token.accessToken, token.tokenType)
        .get(`/api/web/v2/users/me`)
        .catch(() => {
        });

      const { success, output } = get(response, 'data', []);
      status = get(output, '._misc.active', []);
      setMobilePhone(mobilePhone || get(output, '_related.profile.phone_1', ''));

      if (success) {
        id = id || get(output, 'id', '');
        status = status || get(output, '_misc.active', 'active');
        userName = userName || get(output, 'handle', userName);
        profile = profile || get(output, '_related.profile', profile);
        proveVerified = proveVerified || get(output, 'proveVerified', proveVerified);
        proveEligibility = proveEligibility || get(output, 'proveEligibility', proveEligibility);
        user_verified_status = user_verified_status || get(output, 'user_verified_status', user_verified_status);
        setUserVerifiedStatus(user_verified_status);
        setUser(
          {
            id,
            token,
            status,
            params: { userName, profile, mobilePhone },
            proveVerified,
            proveEligibility
          }
        );
        const active = get(output, '_misc.active', []);
        setUserStatus(active === true ? 'active' : 'inactive');
        setCanEditProfile(
          status === 'active' &&
          proveVerified === false &&
          proveEligibility === true
        );
        if (active === true) {
          setPhase('phase4');
        } else {
          setPhase('phase3');
        }
      } else {
        logout();
        navigate('/');
      }
    }
  };

  const loginPhase3 = data => {
    if (!data) {
      return;
    }
    setUserParams({ userName: data.handle });
  };

  const reset = () => {
    setPhase('phase1');
    setConfirmationToken('');
    setMobilePhone('');
  };

  const loginCancel = () => {
    logout();
    navigate('/');
  };

  const verifyToken = useCallback(async () => {
    const response = await http(apiHost, referenceLinkToken)
      .get(`/api/web/v2/connected-apps/check-auth-token-with-reference-link?reference_token=${referenceLinkToken}`)
      .catch(() => {
        setPhase('phase1');
      });

    const { success, output, message } = get(response, 'data', []);
    if (success && message === 'OK') {
      // eslint-disable-next-line camelcase
      const { auth_key, _related, user_verified_status } = output;
      setUserVerifiedStatus(user_verified_status);
      const id = get(_related, 'owner.id', '');
      const token = {
        // eslint-disable-next-line camelcase
        accessToken: auth_key,
        tokenType: 'bearer'
      };

      loginPhase2({
        id,
        token,
        user_verified_status
      });
    }

  }, []);
  /**
   * Effects
   */
  // Set referer page
  useEffect(() => {
    setUserParams({ documentReferer: documentReferer || '' });
  }, [documentReferer]);
  // Verify phase0
  useEffect(() => {
    if (phase === 'phase0') {
      setHeader(() =>
        <Typography
          color="primary"
          variant="h1"
          className="pj-login-header"
        >Welcome</Typography>
      );
      setSubtitle(null);
      setPageClass('Login');
      verifyToken();
    }
  }, []);

  return (
    <LoginLayout>
      {
        phase === 'phase0' &&
        <Box className="pj-full-width pj-text-center">
          <Typography variant="h3">
            <CircularProgress color="primary" /> <br /><br /> We are logging you in...
          </Typography>
        </Box>
      }
      {phase === 'phase1' && <LoginPhone onSubmit={loginPhase1} />}
      {phase === 'phase2' && (
        <VerifySms
          mobilePhone={mobilePhone}
          onSubmit={loginPhase2}
          onReset={reset}
          confirmationToken={confirmationToken}
        />
      )}
      {
        phase === 'phase3' &&
        (
          <Grid
            container
            direction="column"
            alignContent="center"
          >
            <UserProfileSetup onSubmit={loginPhase3} />
          </Grid>
        )
      }
      {
        phase === 'phase4' &&
        (
          <Navigate
            replace
            to={
              userVerifiedStatus === 'PENDING' ?
                PICKLEJAR_DEFAULT_ID_VERIFY_PAGE_URL :
                loginRedirect
            }
          />
        )
      }
      {

        phase === 'phase4' &&
        (
          <Box>
            <Typography
              type="button"
              component="p"
              color="primary"
              onClick={loginCancel}
              sx={{ display: 'flex', mt: 4 }}
            >
              Log out
            </Typography>
          </Box>
        )}
    </LoginLayout>
  );
};

Login.propTypes = {
  loginRedirect: PropTypes.string.isRequired
};

export default Login;
