// Packages
import React from 'react';
import { Box, FormHelperText, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { Controller, useForm } from 'react-hook-form';

// Relatives
import ImageCropInput from '../../../components/ImageCropInput';
import { PICKLEJAR_DEFAULT_ID_VALIDATION_FRONT_IMAGE } from '../index';

const IDVerificationCropImage = props => {
  const {
    canEdit,
    customFileName,
    inputId,
    defaultPreviewUrl,
    fieldName,
    loadingAvatar,
    onUpload,
    previewImageComponent,
    setLoadingAvatar
  } = props;
  /**
   * App Contexts & Params
   */
  const previewUrl = defaultPreviewUrl || PICKLEJAR_DEFAULT_ID_VALIDATION_FRONT_IMAGE;
  const idForm = useForm({
    defaultValues: {
      image: null
    }
  });
  const { control } = idForm;
  /**
   * Handlers
   */
  const onUploadImage = callbackLoading => (name, data) => {
    return onUpload(callbackLoading, name, data);
  };

  return (
    <Box className="image-crop-input border-rounded border-primary">
      <Controller
        name={fieldName}
        control={control}
        render={({ field, fieldState }) => (
          <div className="image-crop-input">
            <ImageCropInput
              aspect={16 / 9.82}
              canEdit={canEdit}
              inputId={inputId}
              loading={loadingAvatar}
              name={field.name}
              onUpload={onUploadImage(setLoadingAvatar)}
              previewUrl={previewUrl}
              showAsAvatar={false}
              showPreview
              customFileName={customFileName}
            />
            {previewImageComponent}
            <FormHelperText>
              {fieldState.error && (
                <Typography
                  variant="p"
                  color="error"
                >
                  {fieldState.error.message}
                </Typography>
              )}
            </FormHelperText>
          </div>
        )}
      />
    </Box>
  );
};

IDVerificationCropImage.defaultProps = {
  canEdit: false,
  children: null,
  customFileName: null,
  defaultPreviewUrl: '',
  fieldName: 'image',
  inputId: null,
  loadingAvatar: false,
  onUpload: null,
  previewImageComponent: null,
  setLoadingAvatar: null
};

IDVerificationCropImage.propTypes = {
  canEdit: PropTypes.bool,
  children: PropTypes.node,
  customFileName: PropTypes.string,
  defaultPreviewUrl: PropTypes.string,
  fieldName: PropTypes.string,
  inputId: PropTypes.string || PropTypes.number,
  loadingAvatar: PropTypes.bool,
  onUpload: PropTypes.func,
  previewImageComponent: PropTypes.node,
  setLoadingAvatar: PropTypes.func,
  submitUrl: PropTypes.string.isRequired
};

export default IDVerificationCropImage;
