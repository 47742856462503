import PropTypes from 'prop-types';

export const ProveVerifyAddress = {
  identityRequestId: PropTypes.string,
  address: PropTypes.string,
  extendedAddress: PropTypes.string,
  city: PropTypes.string,
  region: PropTypes.string,
  postalCode: PropTypes.string
};

export const ProveVerifyModel = {
  requestId: PropTypes.string,
  lineType: PropTypes.string,
  carrier: PropTypes.string,
  countryCode: PropTypes.string,
  firstName: PropTypes.string,
  lastName: PropTypes.string,
  dob: PropTypes.string,
  ssn: PropTypes.string,
  addresses: PropTypes.arrayOf(PropTypes.shape(ProveVerifyAddress)),
  emailAddresses: PropTypes.arrayOf(PropTypes.string)
};
