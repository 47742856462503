// Packages
import React, { useContext } from 'react';
import { Button, Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

// Relatives
import { isNil } from 'lodash';
import { CheckCircle } from '@mui/icons-material';
import IDVerificationContext from '../../../contexts/IDVerificationContext';


const IDVerificationStep1 = props => {
  const {
    renderInput,
    nextStep
  } = props;
  /**
   * App Contexts & Params
   */
  const {
    defaultBackIDPreviewUrl,
    iDVerificationBackImageId,
    iDVerificationFrontImageId
  } = useContext(IDVerificationContext);
  /**
   * Handlers
   */
  const triggerContinue = () => {
    nextStep(true, null, null);
  };

  return (
    <Grid
      item
      xs={12}
    >
      <Grid
        container
        alignItems="start"
      >
        <Grid
          item
          paddingLeft={2}
          paddingRight={2}
          sm={6}
          xs={12}
          sx={{ position: 'relative', mb: 4 }}
        >
          {
            !isNil(iDVerificationFrontImageId) &&
            <CheckCircle
              fontSize="large"
              sx={{ color: '#8BC53F', position: 'absolute', top: - 12, right: 12, zIndex: 5, backgroundColor: 'white', borderRadius: '50%' }}
            />
          }
          {renderInput()}
          <Typography
            component="h2"
            color="white"
            className="pj-option-highlight"
          >Front of ID</Typography>
          <Typography
            component="small"
            color="white"
          >{iDVerificationFrontImageId ? 'Saved' : 'Pending'}</Typography>
        </Grid>

        <Grid
          item
          paddingLeft={2}
          paddingRight={2}
          sm={6}
          xs={12}
          sx={{ position: 'relative', mb: 4 }}
        >
          {
            !isNil(iDVerificationBackImageId) &&
            <CheckCircle
              fontSize="large"
              sx={{ color: '#8BC53F', position: 'absolute', top: - 12, right: 12, zIndex: 5, backgroundColor: 'white', borderRadius: '50%' }}
            />
          }
          <img
            className="image-crop-input border-rounded"
            alt="back of id"
            src={defaultBackIDPreviewUrl}
          />
          <Typography
            component="h2"
            color="white"
            className="pj-option-highlight"
          >Back of ID</Typography>
          <Typography
            component="small"
            color="white"
          >{iDVerificationBackImageId ? 'Saved' : 'Pending'}</Typography>
        </Grid>
      </Grid>
      {
        iDVerificationFrontImageId &&
        <Button
          fullWidth
          variant="contained"
          color="secondary"
          sx={{ mt: 4 }}
          onClick={triggerContinue}
        > Continue </Button>
      }
    </Grid>
  );
};

IDVerificationStep1.propTypes = {
  renderInput: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired
};

export default IDVerificationStep1;
