// Packages
import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { ThemeProvider } from '@mui/material';
import { Navigate, Route, Routes } from 'react-router';

// Relatives
import AppContext from './contexts/AppContext';
import UserProvider from './contexts/UserContext/UserProvider';
import UserContext from './contexts/UserContext/UserContext';
import { getApiHost, getEnvironment, getHost } from './config/settings';
import PicklejarLogin from './pages/PicklejarLogin';

// Styles
import './styles/picklejarTheme.scss';
import { PicklejarTheme } from './const/PicklejarTheme';

// Providers
import NotificationProvider from './contexts/NotificationContext/NotificationProvider';
import NotificationBar from './components/common/notifications/NotificationBar';
import PicklejarProfile from "./pages/PicklejarProfile";
import { PICKLEJAR_DEFAULT_PROFILE_PAGE_URL } from './const/PicklejarSettings';
import PicklejarIDVerification from './pages/PicklejarIDVerification';

const App = props => {
  const { sessionKey } = props;

  const environment = useMemo(() => getEnvironment(), []);

  const apiHost = getApiHost(environment);
  const host = getHost(environment);

  const appContextValue = useMemo(() => (
    { host, apiHost }
  ), [host, apiHost]);
  return (
    <AppContext.Provider value={appContextValue}>
      <UserProvider sessionKey={sessionKey}>
        <NotificationProvider>
          <NotificationBar/>
          <UserContext.Consumer>
            {context => {
              const { isLogged, status } = context;
              return (
                <ThemeProvider theme={PicklejarTheme}>
                  <div className="App">
                    <Routes>
                      {isLogged && <Route
                        exact
                        path="/verify-id"
                        element={<PicklejarIDVerification redirectOnSuccess={PICKLEJAR_DEFAULT_PROFILE_PAGE_URL}/>}
                      />}
                      {isLogged && <Route
                        exact
                        path="/profile"
                        element={<PicklejarProfile isIDVerification/>}
                      />}
                      {status !== 'new-ngo' && (
                        <Route
                          exact
                          path="/"
                          element={
                            <PicklejarLogin loginRedirect={PICKLEJAR_DEFAULT_PROFILE_PAGE_URL}/>}
                        />
                      )}
                      <Route
                        path="*"
                        element={<Navigate
                          replace
                          to="/"
                        />}
                      />
                    </Routes>
                  </div>
                </ThemeProvider>
              );
            }}
          </UserContext.Consumer>
        </NotificationProvider>
      </UserProvider>
    </AppContext.Provider>
  );
};

App.defaultProps = {
  sessionKey: 'kaDa6ptP7Bk2xP3'
};

App.propTypes = {
  sessionKey: PropTypes.string
};

export default App;
