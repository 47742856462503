/* eslint-disable camelcase */
// Packages
import React, { useCallback, useContext, useEffect, useRef, useState } from 'react';
import { Box, Button, CircularProgress, Container, Dialog, DialogActions, DialogTitle, Grid, IconButton, LinearProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import Webcam from 'react-webcam';
import CloseIcon from '@mui/icons-material/Close';
import { divide, get, isEqual, isNil, multiply, round } from 'lodash';

// Relatives
import IDVerificationContext from '../../../contexts/IDVerificationContext';
import IDVerificationCropImage from './IDVerificationCropImage';
import { FACING_MODE_ENVIRONMENT, FACING_MODE_USER, PICKLEJAR_VIDEO_CONSTRAINS } from '../../../const/PicklejarTheme';
import { UploadImage, UploadSupportAttachment } from '../../../services/api/UploadImage';
import AppContext from '../../../contexts/AppContext';
import UserContext from '../../../contexts/UserContext';
import NotificationContext from '../../../contexts/NotificationContext';
import IDVerificationStep1 from './IDVerificationStep1';
import IDVerificationStep2 from './IDVerificationStep2';


export const IDVerificationStep = props => {
  const {
    activeStep,
    loading,
    onSubmit,
    setLoading,
    submitUrl,
    uploadPictureButtonLabel,
    takePictureButtonLabel,
    nextStep
  } = props;
  /**
   * App Contexts & Params
   */
  const { apiHost } = useContext(AppContext);
  const { token } = useContext(UserContext);
  const { notificationError } = useContext(NotificationContext);
  const {
    defaultBackIDPreviewUrl,
    defaultFrontIDPreviewUrl,
    setDefaultBackIDPreviewUrl,
    setDefaultFrontIDPreviewUrl,
    setIDVerificationFrontImageId,
    setIDVerificationBackImageId,
    supportTicket
  } = useContext(IDVerificationContext);
  /**
   * App State
   */
  let customFileName = null;
  if (activeStep === 0) {
    customFileName = 'frontid';
  }

  if (activeStep === 1) {
    customFileName = 'backid';
  }
  const inputId = 'IDVerificationFrontImage';
  const label = useRef(null);
  const webcamRef = useRef(null);
  const [loadingAvatar, setLoadingAvatar] = useState(false);
  const [previewImage, setPreviewImage] = useState(null);
  const [openTakePictureModal, setOpenTakePictureModal] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [showPreviewImage, setShowPreviewImage] = useState(false);
  const [disableCapture, setDisableCapture] = useState(true);
  const [alertMessage, setAlertMessage] = useState('');
  const [mssgUploadProgressBanner, setMssgUploadProgressBanner] = useState({ progress: null, color: 'primary' });
  const [facingMode, setFacingMode] = useState(FACING_MODE_ENVIRONMENT);
  /**
   *
   * Handlers
   */
  const enableCapture = MediaStream => {
    if (MediaStream.active) {
      setTimeout(() => {
        setDisableCapture(false);
      }, 1500);
    }
  };
  const updateImage = (callbackLoading, name, data) => {
    return updateIDVerificationPicture(callbackLoading, name, data);
  };
  const triggerOpenTakePictureModal = () => setOpenTakePictureModal(true);
  const triggerCloseTakePictureModal = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    setOpenTakePictureModal(false);
    setShowPreviewImage(false);
  };
  const triggerCloseAlertModal = (event, reason) => {
    if (reason === 'backdropClick' || reason === 'escapeKeyDown') {
      return;
    }
    setOpenAlertModal(false);
  };
  const showConfirmationImage = img => {
    setPreviewImage(img);
    setShowPreviewImage(true);
  };
  const switchCamera = React.useCallback(() => {
    setFacingMode(
      prevState => prevState === FACING_MODE_USER ?
        FACING_MODE_ENVIRONMENT :
        FACING_MODE_USER
    );
  }, [facingMode]);
  const retryTakeImage = () => {
    setPreviewImage(null);
    setShowPreviewImage(false);
  };
  const confirmImage = () => {
    if (activeStep === 0) {
      setDefaultFrontIDPreviewUrl(previewImage);
    }

    if (activeStep === 1) {
      setDefaultBackIDPreviewUrl(previewImage);
    }

    loadURLToInputFiled(previewImage, customFileName);
  };
  const triggerNextStep = (fileData, success, data, message) => {
    const image = get(fileData, 'croppedImageUrl');
    if (image) {
      if (activeStep === 0) {
        setDefaultFrontIDPreviewUrl(image);
        setIDVerificationFrontImageId(data.id);
      }

      if (activeStep === 1) {
        setDefaultBackIDPreviewUrl(image);
        setIDVerificationBackImageId(data.id);
      }

    }
    if (success) {
      message = 'Image saved successfully';
    }
    onSubmit(success, data, message);
  };
  const getImgURL = (url, callback) => {
    const xhr = new XMLHttpRequest();
    xhr.onload = function () {
      callback(xhr.response);
    };
    xhr.open('GET', url);
    xhr.responseType = 'blob';
    xhr.send();
  };
  const loadURLToInputFiled = (url, name) => {
    getImgURL(url, imgBlob => {
      const fileName = `${name}.jpg`;
      const file = new File([imgBlob], fileName, { type: 'image/jpeg', lastModified: new Date().getTime() }, 'utf-8');
      const container = new DataTransfer();
      container.items.add(file);
      const fileData = {
        croppedImageUrl: previewImage,
        file: container.files[0],
        originalFile: container.files[0],
        originalSrc: previewImage
      };

      updateIDVerificationPicture(setLoadingAvatar, 'image', fileData);
    });
  };
  /**
   * Handlers
   */
  const openFileSelector = () => {
    label.current.click();
  };
  const handleBackdropClick = event => {
    // these fail to keep the modal open
    event.stopPropagation();
    return false;
  };
  const triggerIsLoading = loadingStatus => {
    setLoading(loadingStatus);
    setLoadingAvatar(loadingStatus);
    setOpenAlertModal(loadingStatus);
  };
  const uploadProgressBanner = data => {
    const messageUploadOnProgress = round(multiply(divide(data.loaded, data.total), 100));
    const messageUploadEnd = 100;

    if (isEqual(data.loaded, data.total)) {
      setMssgUploadProgressBanner({ progress: messageUploadEnd, color: 'success' });

      setTimeout(() => {
        setMssgUploadProgressBanner({ progress: null, color: 'dark' });
      }, 3000);

      return messageUploadEnd;
    }

    if (!isEqual(data.loaded, data.total)) {
      setMssgUploadProgressBanner({ progress: messageUploadOnProgress, color: 'warning' });
      return messageUploadOnProgress;
    }


    return false;
  };
  /**
   * API Requests
   */
  const triggerUploadAttachment = async media_id => {
    setAlertMessage('Updating request...');
    const ticket_head_id = get(supportTicket, '_related.ticket.id');
    const updateAttachmentResponse = await UploadSupportAttachment(
      apiHost,
      token.accessToken,
      token.tokenType,
      uploadProgressBanner,
      ticket_head_id,
      null,
      media_id
    );
    const data = get(updateAttachmentResponse, 'data', {});
    const success = get(data, 'success', false);
    if (!success) {
      notificationError('Something went wrong. please try again');
    }
    return data;
  };
  const updateIDVerificationPicture = async (callbackLoading, input, fileData) => {
    if (!token) {
      notificationError('Missing validation token');
      return;
    }

    if (!submitUrl) {
      notificationError('Missing url parameter');
      return;
    }

    setAlertMessage('Uploading image...');
    triggerIsLoading(true);
    triggerCloseTakePictureModal();
    const response = await UploadImage(apiHost, token.accessToken, token.tokenType, fileData, uploadProgressBanner);
    const { success, data } = get(response, 'data', {});

    if (!response || !data) {
      notificationError('Something went wrong. please try again');
      triggerIsLoading(false);
      return;
    }

    if (success) {
      if (data.files) {
        let message = '';
        data.files.forEach(file => {
          message += file.error;
        });
        notificationError(message);
        triggerIsLoading(false);
        return;
      }
      const mediaId = get(data[0], 'id', null);
      triggerUploadAttachment(mediaId).then(request => {
        triggerIsLoading(false);
        if (request.success) {
          triggerNextStep(fileData, request.success, request.output, request.message);
        }
      });
    } else if (!data) {
      notificationError('Something went wrong. please try again');
      triggerIsLoading(false);
    }
  };
  const handleCaptureImage = useCallback(
    () => {
      const imageSrc = webcamRef.current.getScreenshot();
      showConfirmationImage(imageSrc);
    },
    [webcamRef]
  );
  const renderInput = () => {
    return (
      <IDVerificationCropImage
        defaultPreviewUrl={activeStep === 0 ? defaultFrontIDPreviewUrl : defaultBackIDPreviewUrl}
        inputId={inputId}
        loading={loading}
        loadingAvatar={loadingAvatar}
        onUpload={updateImage}
        setLoadingAvatar={setLoadingAvatar}
        setLoading={setLoading}
        submitUrl={submitUrl}
        customFileName={customFileName}
      />
    );
  };
  /**
   * Effects
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    if (!isNil(mssgUploadProgressBanner.progress)) {
      setLoading(true);
    }

    if (isNil(mssgUploadProgressBanner.progress)) {
      setLoading(false);
    }
  }, [mssgUploadProgressBanner.progress, setLoading]);

  return (
    <Container
      className="pj-user-profile__form"
      maxWidth="md"
    >
      <Grid container>
        <Grid
          item
          xs={12}
        >
          <Grid container>
            {
              activeStep === 0 ?
                <IDVerificationStep1
                  activeStep={activeStep}
                  nextStep={nextStep}
                  renderInput={renderInput}
                /> :
                <IDVerificationStep2
                  activeStep={activeStep}
                  nextStep={nextStep}
                  renderInput={renderInput}
                />
            }
          </Grid>
        </Grid>
        <Grid
          item
          paddingLeft={2}
          paddingRight={2}
          xs={12}
        >
          <Grid container>
            <Grid
              item
              xs={12}
            >
              {
                loadingAvatar ?
                  <CircularProgress
                    color="primary"
                    size={24}
                  /> :
                  <>

                    <Button
                      color="primary"
                      onClick={triggerOpenTakePictureModal}
                      disabled={loading || loadingAvatar}
                      fullWidth
                      sx={{ my: 2 }}
                    >
                      {takePictureButtonLabel}
                    </Button>

                    <Button
                      color="primary"
                      variant="outlined"
                      onClick={openFileSelector}
                      disabled={loading || loadingAvatar}
                      fullWidth
                    >
                      {uploadPictureButtonLabel}
                    </Button>
                  </>
              }
              <label
                htmlFor={inputId}
                ref={label}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Dialog
        onClose={triggerCloseTakePictureModal}
        open={openTakePictureModal}
        fullWidth
        disableEscapeKeyDown
        onBackdropClick={handleBackdropClick}
      >
        <DialogTitle>
          {takePictureButtonLabel}
          <IconButton
            aria-label="close"
            onClick={triggerCloseTakePictureModal}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              width: 48,
              height: 48
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        {showPreviewImage ?
          <>
            <img
              src={previewImage}
              alt="preview"
            />
            <DialogActions sx={{ p: 2 }}>
              <Button
                fullWidth
                variant="outlined"
                onClick={retryTakeImage}
              >
                Retry
              </Button>

              <Button
                fullWidth
                variant="contained"
                onClick={confirmImage}
              >
                Confirm
              </Button>
            </DialogActions>
          </> :
          <>
            {
              disableCapture &&
              <Typography
                variant="body1"
                sx={{
                  textAlign: 'center',
                  position: 'absolute',
                  top: '50%',
                  left: '50%',
                  transform: 'translate(-50%, -50%)',
                  zIndex: 0
                }}
              >
                <CircularProgress
                  color="primary"
                  size={18}
                /> Accessing to your camera to take a picture
              </Typography>
            }
            <Webcam
              audio={false}
              width="100%"
              height="auto"
              facingmode="environment"
              minScreenshotHeight={640}
              minScreenshotWidth={480}
              onUserMedia={enableCapture}
              screenshotFormat="image/jpeg"
              videoConstraints={{
                ...PICKLEJAR_VIDEO_CONSTRAINS,
                facingMode
              }}
              ref={webcamRef}
            />
            <DialogActions sx={{ justifyContent: 'center', p: 2 }}>
              <Button
                fullWidth
                variant="contained"
                color="secondary"
                onClick={switchCamera}
                disabled={disableCapture}
              >
                Switch Camera
              </Button>
              <Button
                fullWidth
                variant="contained"
                onClick={handleCaptureImage}
                disabled={disableCapture}
              >
                Capture photo
              </Button>
            </DialogActions>
          </>
        }
      </Dialog>

      <Dialog
        onClose={triggerCloseAlertModal}
        open={openAlertModal}
        fullWidth
        disableEscapeKeyDown
        onBackdropClick={handleBackdropClick}
      >
        <DialogTitle>Please Wait...</DialogTitle>
        <Typography
          paragraph={false}
          variant="body1"
          sx={{
            textAlign: 'center',
            zIndex: 0,
            my: 4
          }}
        >
          <CircularProgress
            color="primary"
            size={18}
          /> {alertMessage}
        </Typography>
        <Box sx={{ my: 2, px: 4 }}>
          {!isNil(mssgUploadProgressBanner.progress) &&
           <LinearProgress
             className="banner__progress-overlay"
             value={mssgUploadProgressBanner.progress}
             color="primary"
           >
             <span className="text-dark">{mssgUploadProgressBanner.progress}%</span>
           </LinearProgress>
          }
        </Box>
      </Dialog>
    </Container>
  );
};

IDVerificationStep.defaultProps = {
  uploadPictureButtonLabel: 'Upload a photo of front ID',
  takePictureButtonLabel: 'Take a photo of front ID'
};

IDVerificationStep.propTypes = {
  activeStep: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired,
  nextStep: PropTypes.func.isRequired,
  setLoading: PropTypes.func.isRequired,
  submitUrl: PropTypes.string.isRequired,
  takePictureButtonLabel: PropTypes.string,
  uploadPictureButtonLabel: PropTypes.string
};

export default IDVerificationStep;
