// Packages
import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { IMaskInput } from 'react-imask';
import omit from 'lodash/omit';

const MaskedInput = forwardRef((props, ref) => {
  const { onChange, name, valueMasked, value } = props;

  return (
    <IMaskInput
      definitions={{
        '#': '/[0-9]/'
      }}
      {...omit(props, ['onChange', 'valueMasked'])}
      inputRef={ref}
      onAccept={(val, mask) => {
        if ((
              valueMasked && value !== val
            ) || (
              !valueMasked && value !== mask.unmaskedValue
            )) {
          onChange({ target: { name, value: valueMasked ? val : mask.unmaskedValue } });
        }
      }}
      overwrite
    />
  );
});

MaskedInput.defaultProps = {
  value: '',
  name: '',
  mask: '(#00) 000-0000',
  valueMasked: true,
  onChange: noop
};

MaskedInput.propTypes = {
  value: PropTypes.string,
  name: PropTypes.string,
  mask: PropTypes.string,
  valueMasked: PropTypes.bool,
  onChange: PropTypes.func
};

export default MaskedInput;
