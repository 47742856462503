import PropTypes from 'prop-types';

export const UserModel = {
  id: PropTypes.number,
  handle: PropTypes.string,
  _related: PropTypes.array,
  _misc: PropTypes.array,
  _links: PropTypes.array
};

export default UserModel;
