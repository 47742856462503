import { FormatBytes } from '../utils/FormatBytes';

export const CreateAttachmentData = fileList => {
  return fileList.map(file => {
    const image = URL.createObjectURL(file);
    const title = file.name;
    return {
      file,
      status: 'pending',
      _misc: {
        media: {
          medium_image: image,
          original_image: image,
          title,
          alt: title,
          description: null,
          size: FormatBytes(file.size)
        }
      }
    };
  });
};

export const RemoveAttachmentData = (formAttachments, index) => {
  const newArray = [...formAttachments];
  newArray.splice(index, 1);
  return newArray;
};
