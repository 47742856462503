export const getApiHost = environment => {
  switch (environment) {
    case 'live':
      return 'https://api.pkle.live';
    case 'uat':
      return 'https://dhiraj.pkle.live';
    case 'staging':
      return 'https://staging.pkle.live';
    case 'development':
      return 'https://dev.pkle.live';
    default:
      return 'https://dev.pkle.live';
  }
};

export const getHost = environment => {
  switch (environment) {
    case 'live':
      return 'https://pkle.live';
    case 'uat':
      return 'https://dhiraj.pkle.live';
    case 'staging':
      return 'https://staging.pkle.live';
    case 'development':
      return 'https://dev.pkle.live';
    default:
      return 'https://dev.pkle.live';
  }
};

export const getEnvironment = () => {
  switch (window.location.hostname) {
    case 'pkle.live':
    case 'associations.picklejar.com':
      return 'live';
    case 'dhiraj.pkle.live':
      return 'uat';
    case 'staging.pkle.live':
    case 'staging-associations.picklejar.com':
      return 'staging';
    case 'dev-associations.picklejar.com':
      return 'development';
    default:
      return 'maintenance';
  }
};
