// Packages
import React, { useId, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { isEmpty } from 'lodash';
import pick from 'lodash/pick';
import get from 'lodash/get';
import { Avatar, Badge, Box, CircularProgress, TextField } from '@mui/material';
import Stack from '@mui/material/Stack';
// Relatives
import { CheckCircle } from '@mui/icons-material';
import ImageModalCrop from './ImageModalCrop';
// Style
import 'react-image-crop/dist/ReactCrop.css';
import { PICKLEJAR_DEFAULT_AVATAR_IMAGE, PICKLEJAR_DEFAULT_EDIT_ICON, picklejarLightBackground } from '../../const/PicklejarTheme';

const ImageCropInput = props => {
  const {
    aspect,
    canEdit,
    children,
    loading,
    inputId,
    name,
    onCancel,
    onUpload,
    previewUrl,
    showAsAvatar,
    showPreview,
    showVerifyStatus,
    customFileName
  } = props;
  const [src, setSrc] = useState(null);
  const [originalFile, setOriginalFile] = useState(null);
  const [crop, setCrop] = useState({ unit: '%', width: 30, aspect });
  const [openCrop, setOpenCrop] = useState(false);
  let id = useId();
  if (!isEmpty(inputId)) {
    id = inputId;
  }
  const inputRef = useRef(id);

  const renderBadges = () => {
    if (canEdit === true) {
      return <Box
        component="label"
        htmlFor={id}
        sx={{
          width: '36px',
          height: '36px',
          borderRadius: '50%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          cursor: 'pointer'
        }}
      >
        {
          loading === true ?
            <CircularProgress
              color="primary"
              size={20}
            /> :
            <img
              src={PICKLEJAR_DEFAULT_EDIT_ICON}
              alt="edit icon"
            />
        }
      </Box>;
    }
    return null;
  };

  const defaultChildren = () => (
    children ||
    <Stack
      direction="row"
      spacing={2}
    >
      {
        showAsAvatar ?
          <Badge
            badgeContent={
              renderBadges()
            }
            color="primary"
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right'
            }}
            sx={{
              backgroundColor: 'transparent',
              padding: 0,
              '.MuiBadge-badge': {
                backgroundColor: 'transparent',
                width: 'auto',
                padding: 0
              }
            }}
          >
            <Avatar
              alt="profile"
              src={previewUrl}
              sx={{ width: '120px', height: '120px', border: 'none' }}
            />
          </Badge> :
          <img
            alt="crop"
            src={previewUrl}
          />
      }
      {
        showVerifyStatus === true &&
        <CheckCircle
          color="success"
          sx={{
            position: 'absolute',
            backgroundColor: 'white',
            borderRadius: '50%',
            right: 0,
            bottom: 12
          }}
        />
      }
    </Stack>
  );
  const onSelectFile = e => {
    if (e.target.files && e.target.files.length > 0) {
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        setSrc(reader.result);
        setOpenCrop(true);
      });
      reader.readAsDataURL(e.target.files[0]);
      setOriginalFile(e.target.files[0]);
    }
  };

  const resetState = () => {
    setOpenCrop(false);
    setSrc(null);
    setCrop({ unit: '%', width: 30, aspect });
    setOriginalFile(null);
  };

  const handleClickCancel = () => {
    setOpenCrop(false);
    resetState();
    onCancel();
  };

  const handleClickSubmit = async croppedImageUrl => {
    const blob = await fetch(croppedImageUrl).then(r => r.blob());
    let fileName = originalFile.name;

    if (customFileName) {
      const extension = originalFile.name.split('.').pop();
      fileName = `${customFileName}.${extension}`;
    }

    onUpload(name, {
      originalSrc: src,
      originalFile,
      croppedImageUrl,
      file: new File([blob], fileName, { ...pick(originalFile, ['type']) })
    });
    resetState(true);
  };

  return (
    <Box className="image-crop-input">
      <Box className="image-crop-input__input">
        <TextField
          id={id}
          name={name}
          type="file"
          ref={inputRef}
          label=""
          sx={{ backgroundColor: picklejarLightBackground }}
          inputProps={{
            multiple: true,
            accept: 'image/*'
          }}
          hidden
          disabled={loading}
          onChange={onSelectFile}
        />
        {
          showPreview && defaultChildren()
        }
      </Box>
      <ImageModalCrop
        className="image-input__modal"
        src={src}
        fileName={get(originalFile, 'fileName', '')}
        openModal={openCrop}
        onClose={handleClickCancel}
        onSubmit={handleClickSubmit}
        crop={crop}
        setCrop={setCrop}
      />
    </Box>
  );
};

ImageCropInput.defaultProps = {
  aspect: 16 / 9,
  canEdit: true,
  children: null,
  customFileName: null,
  inputId: null,
  loading: false,
  name: '',
  onUpload: noop,
  onCancel: noop,
  previewUrl: PICKLEJAR_DEFAULT_AVATAR_IMAGE,
  showAsAvatar: true,
  showPreview: true,
  showVerifyStatus: false
};

ImageCropInput.propTypes = {
  aspect: PropTypes.number,
  canEdit: PropTypes.bool,
  children: PropTypes.node,
  customFileName: PropTypes.string,
  inputId: PropTypes.string || PropTypes.number,
  loading: PropTypes.bool,
  name: PropTypes.string,
  onUpload: PropTypes.func,
  onCancel: PropTypes.func,
  previewUrl: PropTypes.string,
  showAsAvatar: PropTypes.bool,
  showPreview: PropTypes.bool,
  showVerifyStatus: PropTypes.bool
};

export default ImageCropInput;
