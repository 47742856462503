/* eslint-disable camelcase */
import React, { useContext, useEffect, useState } from 'react';
import { Collapse, List, ListItemButton, ListItemText, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { get } from 'lodash';
import classNames from 'classnames';

// Relatives
import { picklejarBorderInputColor } from '../../../const/PicklejarTheme';
import AttachmentMediaList from './AttachmentMediaList';
import AttachmentUploadList from './AttachmentUploadList';
import { UploadImage } from '../../../services/api/UploadImage';
import UserContext from '../../../contexts/UserContext';
import AppContext from '../../../contexts/AppContext';
import NotificationContext from '../../../contexts/NotificationContext';

const AttachmentList = props => {
  /**
   * App Contexts & Params
   */
  const { apiHost } = useContext(AppContext);
  const { token } = useContext(UserContext);
  const { notificationError, notificationSuccess } = useContext(NotificationContext);
  /**
   * App State
   */
  const {
    attachmentList,
    uploadAttachments,
    isLoading,
    removeAttachmentCallback,
    transferCompletedCallback,
    uploadFiles,
    defaultState,
    customClassName
  } = props;
  const [openAttachments, setOpenAttachments] = useState(defaultState);
  const [formAttachments, setFormAttachments] = useState([]);
  const toggleOpenAttachments = () => {
    setOpenAttachments(!openAttachments);
  };
  const uploadMedias = async () => {
    const totalAttachments = [];
    const totalSuccess = [];
    const mediaList = [...attachmentList];
    mediaList.map(async (file, index) => {
      if (file.status === 'pending') {
        mediaList[index] = { ...attachmentList[index], status: 'loading' };
        setFormAttachments([...mediaList]);
        const response = await UploadImage(apiHost, token.accessToken, token.tokenType, file);
        const { success, data } = get(response, 'data', {});
        totalAttachments.push({ success, data });
        if (success === true) {
          totalSuccess.push(data);
          if (data && data[0] && data[0].id) {
            mediaList[index] = { ...mediaList[index], status: 'success' };
            notificationSuccess(`${file.file.name} saved`);
          }

          // Handle Errors
          if (data.files) {
            mediaList[index] = { ...attachmentList[index], status: 'error' };
            notificationError(`${file.file.name} could not be saved. Error: ${data.files[0].error}`);
          }
        } else {
          mediaList[index] = { ...attachmentList[index], status: 'error' };
        }
        setFormAttachments([...mediaList]);
      }
      if (totalAttachments.length === formAttachments.length) {
        if (transferCompletedCallback) {
          transferCompletedCallback(totalAttachments, totalSuccess);
        }
      }
    });
  };

  useEffect(() => {
    if (uploadFiles === true) {
      uploadMedias().catch(err => console.log(err));
    }
  }, [uploadFiles]);
  useEffect(() => {
    setFormAttachments(attachmentList);
  }, [attachmentList]);

  return (
    <List
      className={classNames('pj-attachment-list', customClassName)}
      component="nav"
      sx={{ width: '100%' }}
      key="attachmentList"
    >
      <ListItemButton
        onClick={toggleOpenAttachments}
        sx={{ backgroundColor: picklejarBorderInputColor }}
        componen="div"
      >
        <ListItemText
          component="div"
          primary={
            <Typography
              className="pj-attachment-header"
              component="span"
              variant="body2"
              color="text.primary"
            > {formAttachments.length} Attachments</Typography>
          }
        />
        {openAttachments === true ? <ExpandLess /> : <ExpandMore />}
      </ListItemButton>
      <Collapse
        component="div"
        in={openAttachments === true}
        timeout="auto"
        unmountOnExit
      >
        <List
          component="div"
          disablePadding
          sx={{ p: 3 }}
        >
          {
            uploadAttachments === true &&
            (
              <AttachmentUploadList
                key={`attachmentUploadList-${uploadAttachments.length}`}
                attachmentList={formAttachments}
                removeAttachment={removeAttachmentCallback}
                isLoading={isLoading}
              />
            )
          }
          {
            uploadAttachments === false &&
            (
              <AttachmentMediaList
                key={`attachmentUploadList-${uploadAttachments.length}`}
                attachmentList={attachmentList}
              />
            )
          }
        </List>
      </Collapse>
    </List>
  );
};

AttachmentList.defaultProps = {
  uploadAttachments: false,
  defaultState: true,
  isLoading: false,
  uploadFiles: true,
  removeAttachmentCallback: null,
  transferCompletedCallback: null,
  customClassName: null
};

AttachmentList.propTypes = {
  attachmentList: PropTypes.any.isRequired,
  uploadAttachments: PropTypes.bool,
  defaultState: PropTypes.bool,
  isLoading: PropTypes.bool,
  uploadFiles: PropTypes.bool,
  removeAttachmentCallback: PropTypes.func,
  transferCompletedCallback: PropTypes.func,
  customClassName: PropTypes.string
};

export default React.memo(AttachmentList);
