// Packages
import React, { useCallback, useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Box, Button, CircularProgress, Container, Grid, List, ListItem, TextField, Typography } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import noop from 'lodash/noop';
import get from 'lodash/get';
import omit from 'lodash/omit';

// Relatives
import AppContext from '../../../contexts/AppContext';
import LayoutContext from '../../../contexts/LayoutContext';
import http from '../../../services/api/http';

const VerifySms = props => {
  const { mobilePhone, onSubmit, onReset, confirmationToken } = props;
  /**
   * App Contexts & Params
   */
  const { apiHost } = useContext(AppContext);
  const {
    setHeader,
    setSubtitle,
    setPageTitle,
    setPageClass,
    setHideLogo
  } = useContext(LayoutContext);
  /**
   * App State
   */
  const verifySmsForm = useForm({});
  const { clearErrors, control, handleSubmit, formState: { errors, isValid }, setError } = verifySmsForm;
  const [enableSubmit, setEnableSubmit] = useState(true);
  const [loading, setLoading] = useState(false);
  const verifySmsError = get(errors, 'verifySms.message');
  /**
   * Handlers
   */
  const handleFormSubmit = async formData => {
    if (!formData || !confirmationToken) {
      return;
    }
    setLoading(true);
    setEnableSubmit(false);
    const confirmationCode = Object.values(formData).join('');
    const response = await http(apiHost)
      .post('/api/web/v1/new-login-flow/new-login', {
        confirmation_numbers: confirmationCode,
        confirmation_token: confirmationToken
      })
      .catch(() => {
      });

    if (!response || !response.data) {
      setLoading(false);

      return;
    }

    const { success, data, error } = get(response, 'data', {});
    if (!success && error.fieldErrors.confirmation_numbers) {
      setError('verifySms', { type: 'checkCode', message: error.fieldErrors.confirmation_numbers });
      setLoading(false);
      return;
    }

    const { id, token, userName, status, profile, proveVerified, proveIdentity, proveEligibility } = data;
    onSubmit({
        token,
        userName,
        id,
        status,
        profile: { avatar: profile.avatar },
        proveVerified,
        proveIdentity,
        proveEligibility
      }
    );
  };
  const focusNextInput = e => {
    const { tabIndex, value } = e.target;
    const index = parseInt(tabIndex, 10);
    if (value.length === 1 && index < 6) {
      const nextSibling = document.querySelector(`input[tabindex="${index + 1}"]`);
      if (nextSibling !== null) {
        nextSibling.focus();
      }
    }
    setEnableSubmit(true);
  };
  /**
   * Effects
   */
  const initComponent = useCallback(() => {
    setPageTitle('Login');
    setHideLogo(true);
    setHeader(
      <Typography
        color="primary"
        variant="h1"
        className="pj-login-header"
      >We want to make <br />sure It’s you</Typography>
    );
    setSubtitle(
      <Typography
        variant="body2"
        className="pj-login-subtitle"
      >Enter the confirmation code <br />sent to {mobilePhone}</Typography>
    );
    setPageClass('VerifySms');
  }, [setPageTitle, setHideLogo, setHeader, setSubtitle, setPageClass, mobilePhone]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    initComponent();
  }, [initComponent]);
  useEffect(() => {
    if (isValid && enableSubmit) {
      handleSubmit(handleFormSubmit)();
    }

    // TODO: eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValid, enableSubmit]);

  return (
    <>
      <Box className={loading ? 'loading-overlay' : 'loading-overlay hidden'}>
        <CircularProgress color="primary" />
      </Box>
      <Grid
        container
        direction="column"
        component="form"
        onSubmit={handleSubmit(handleFormSubmit)}
        autoComplete="off"
        className="pj-content-section-container  pj-content-section-middle pj-login-verifySms"
      >
        <Container maxWidth="sm">
          <List className="pj-otp-input-list">
            <ListItem disablePadding>
              {Array.from({ length: 6 }).map((_, i) => (
                <Controller
                  key={`verifySmsInput${i}`}
                  name={`verifySms${i}`}
                  control={control}
                  defaultValue=""
                  rules={{
                    required: 'This field is required',
                    validate: {
                      checkCode: () => {
                        const { verifySms } = errors;
                        if (verifySms) {
                          clearErrors('verifySms');
                        }
                      }
                    }
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      type="number"
                      pattern="[0-9]*"
                      label=""
                      id={`submitSmsForm_${i + 1}`}
                      variant="standard"
                      color="primary"
                      size="medium"
                      className="pj-light-underline"
                      onChange={e => {
                        const getFirstOnly = e.target.value.slice(0, 1);
                        field.onChange(getFirstOnly);
                        focusNextInput(e);
                      }}
                      inputProps={{
                        tabIndex: i + 1,
                        maxLength: 1,
                        className: 'pj-text-center pj-otp-input',
                        style: { color: '#FFF' },
                        placeholder: '·',
                        size: 'medium'
                      }}
                    />
                  )}
                />
              ))}
            </ListItem>
            <List>
              <ListItem disablePadding>
                {verifySmsError && (
                  <Typography
                    variant="p"
                    color="error"
                    textAlign="center"
                    display="block"
                    width="100%"
                  >
                    {verifySmsError}
                  </Typography>
                )}
              </ListItem>
              <ListItem disablePadding>
                {Object.keys(omit(errors, ['verifySms']))?.length > 0 && (
                  <Typography
                    variant="p"
                    color="error"
                    textAlign="center"
                  >
                    All fields are required
                  </Typography>
                )}
              </ListItem>
              <ListItem disablePadding>
                <Typography
                  component="small"
                  variant="body2"
                  textAlign="center"
                  width="100%"
                >
                  Fat fingered the wrong number number?{' '}
                  <Button
                    type="reset"
                    color="primary"
                    variant="text"
                    onClick={onReset}
                  >
                    Click Here
                  </Button>
                </Typography>
              </ListItem>
            </List>
          </List>
        </Container>
        <Box className="d-block">
          <Button
            type="submit"
            size="large"
            color="secondary"
            disabled={loading}
            fullWidth
          >
            Submit
          </Button>
          <Box sx={{ mt: 4 }}>
            <Button
              onClick={onReset}
              type="button"
              variant="text"
              size="small"
              color="primary"
              disabled={loading}
            >
              Go back
            </Button>
          </Box>
        </Box>
      </Grid>
    </>
  );
};

VerifySms.defaultProps = {
  onSubmit: noop,
  onReset: noop,
  mobilePhone: '',
  confirmationToken: ''
};

VerifySms.propTypes = {
  onSubmit: PropTypes.func,
  onReset: PropTypes.func,
  mobilePhone: PropTypes.string,
  confirmationToken: PropTypes.string
};

export default VerifySms;
