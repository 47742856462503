import React, { useContext, useEffect, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Box, CircularProgress, Grid, IconButton, List, Paper, TextField, Typography } from '@mui/material';
import { Send } from '@mui/icons-material';
import get from 'lodash/get';
import PropTypes from 'prop-types';
// Relatives
import http from '../../../services/api/http';
import AttachmentList from '../../../components/common/attachments/AttachmentList';
import { picklejarBorderInputColor, picklejarBoxShadow, picklejarLightBackground, picklejarTextColorGray } from '../../../const/PicklejarTheme';
import AppContext from '../../../contexts/AppContext';
import UserContext from '../../../contexts/UserContext';
import NotificationContext from '../../../contexts/NotificationContext';
import { RemoveAttachmentData } from '../../../components/common/attachments/AttachmentData';
import IDVerificationMessages from '../IDVerificationMessages/IDVerificationMessages';

const IDVerificationChat = props => {
  const { loadTicketDetails, setLoading, ticketResponse, loading } = props;
  /**
   * App Contexts & Params
   */
  const { apiHost } = useContext(AppContext);
  const { token } = useContext(UserContext);
  const { notificationError } = useContext(NotificationContext);
  /**
   * App State
   */
  const ticketHeaderAttachment = get(ticketResponse, '_related.ticket._misc.attachments');
  const ticketContent = get(ticketResponse, '_related.ticket._related.ticket_content');
  const ticketId = get(ticketResponse, 'id');
  const ticketHeaderId = get(ticketResponse, '_related.ticket.id');
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [uploadFiles, setUploadFiles] = useState(false);
  const [successfullyMessageSubmitted, setSuccessfullyMessageSubmitted] = useState({
    success: null,
    output: null,
    message: null
  });
  /**
   * Form State
   */
  const chatMessageForm = useForm({
    defaultValues: {
      text: '',
      attachment: []
    }
  });
  const { control, handleSubmit, reset } = chatMessageForm;
  const [formAttachments, setFormAttachments] = useState([]);
  // const [uploadedAttachments, setUploadedAttachments] = useState([]);
  /**
   * Handlers
   */
  /* const onChange = event => {
   setFormAttachments([...formAttachments, ...CreateAttachmentData([...event.target.files])]);
   }; */
  const removeAttachment = index => {
    setFormAttachments(RemoveAttachmentData(formAttachments, index));
  };
  const resetLoadingState = () => {
    setLoadingSubmit(false);
    setSuccessfullyMessageSubmitted(null);
    setUploadFiles(false);
    reset();
  };
  const submitIDVerificationMessage = async formData => {
    const { text } = formData;
    if (!formData || !token) {
      return;
    }
    setLoadingSubmit(true);
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .post('/api/web/v2/support/support-body', {
        id_head: ticketHeaderId,
        text
      })
      .catch(err => {
        notificationError(err.message);
      });
    const { success, output, message } = get(response, 'data', {});
    if (success) {
      setSuccessfullyMessageSubmitted({ success, output, message });
      loadTicketDetails(ticketId);
    }
  };
  const uploadAttachmentCompleted = (totalAttachments, totalSuccess) => {
    setLoadingSubmit(false);
    if (formAttachments.length === totalAttachments.length) {
      if (formAttachments.length === totalSuccess.length) {
        // setUploadedAttachments(totalAttachments);
        setUploadFiles(false);
      } else {
        notificationError('There was an error with some attachments. Please try again');
      }
    }
  };
  /**
   * Effects
   */
  useEffect(() => {
    if (ticketResponse) {
      setLoading(false);
    }
  }, [ticketResponse]);
  useEffect(() => {
    setLoading(false);
    setIsFirstLoad(false);
    resetLoadingState();
  }, [setSuccessfullyMessageSubmitted, successfullyMessageSubmitted]);

  return (
    ticketResponse &&
    <FormProvider {...chatMessageForm}>
      <Paper
        elevation={2}
        className="pj-claims-details__chat"
        sx={
          {
            mt: 3,
            mb: 5,
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between'
          }
        }
      >
        {loading && !loadingSubmit && isFirstLoad ?
          <Grid
            container
            justifyContent="center"
          ><CircularProgress sx={{ mt: 5, mb: 5, alignSelf: 'center' }} /> </Grid> :
          <>
            <Box
              className="pj-claims-details__chat-content"
              component="div"
            >
              <Box
                sx={{ mx: 0, mb: 4, p: 3 }}
                bgcolor={picklejarTextColorGray}
              >
                <Typography variant="h5">Subject:</Typography>
                <Typography
                  variant="body2"
                  color="text.secondary"
                >{get(ticketResponse, '_related.ticket.related.department.name', '')}</Typography>
                {
                  ticketHeaderAttachment?.length > 0 &&
                  (
                    <AttachmentList
                      attachmentList={ticketHeaderAttachment}
                      uploadFiles={false}
                    />
                  )
                }
              </Box>
              {
                ticketContent?.length > 0 &&
                (
                  <List
                    sx={{
                      maxHeight: '60vh',
                      overflow: 'auto',
                      display: 'flex',
                      flexDirection: 'column-reverse',
                      boxShadow: { xs: 'unset', md: picklejarBoxShadow },
                      borderRadius: '1.5rem',
                      paddingBottom: '1.5rem'
                    }}
                  >
                    {
                      ticketContent.map(content =>
                        <IDVerificationMessages
                          key={content.id}
                          content={content}
                          isSameUser={content.isSameUser}
                          isSupport={content.isSupport}
                          uploadFiles={false}
                        />)
                    }
                  </List>
                )
              }
              {
                loading &&
                (
                  <Grid
                    container
                    justifyContent="center"
                  ><CircularProgress sx={{ mt: 5, mb: 5, alignSelf: 'center' }} /> </Grid>
                )
              }
            </Box>

            <Box
              component="form"
              onSubmit={handleSubmit((onSubmit, onError) => submitIDVerificationMessage(onSubmit, onError))}
              autoComplete="off"
              sx={{
                borderTop: `solid thin ${picklejarBorderInputColor}`,
                position: 'fixed',
                bottom: 0,
                left: 0,
                width: '100%',
                backgroundColor: picklejarLightBackground
              }}
              className="claims-details__form"
            >
              {
                formAttachments.length > 0 &&
                (
                  <AttachmentList
                    key="attachmentList"
                    attachmentList={formAttachments}
                    uploadAttachments
                    removeAttachmentCallback={removeAttachment}
                    transferCompletedCallback={uploadAttachmentCompleted}
                    isLoading={loading || loadingSubmit}
                    uploadFiles={uploadFiles}
                  />
                )
              }

              <div className="input-control pj-d-flex">
                <Controller
                  name="attachment"
                  control={control}
                  render={() => (
                    <IconButton
                      color="inherit"
                      aria-label="upload picture"
                      component="label"
                      sx={{ mx: 2, mt: 1 }}
                      disabled={loading || loadingSubmit}
                    >
                      {/*                      <TextField
                       {...field}
                       disabled={loading || loadingSubmit}
                       type="file"
                       label=""
                       className="claims-details__attachment"
                       sx={{
                       backgroundColor: picklejarLightBackground,
                       mb: 0
                       }}
                       inputProps={{
                       multiple: true,
                       accept: 'application/pdf, image/png, image/jpg, image/jpeg'
                       }}
                       hidden
                       onChange={onChange}
                       />
                       <Attachment /> */}
                    </IconButton>
                  )}
                />

                <Controller
                  name="text"
                  control={control}
                  rules={{ required: 'Message Is Required' }}
                  render={({ field }) =>
                    <TextField
                      {...field}
                      label="Message"
                      className="claims-details__messages"
                      sx={{
                        backgroundColor: picklejarLightBackground,
                        mb: 0,
                        mt: 1
                      }}
                      disabled={loadingSubmit}
                    />
                  }
                />
                <IconButton
                  type="submit"
                  sx={{ ml: 2, mt: 1, borderRadius: '50%' }}
                  variant="contained"
                  color="primary"
                  disabled={loadingSubmit}
                >
                  {!loadingSubmit ? <Send /> :
                    <CircularProgress
                      color="primary"
                      style={{ width: '24px', height: '24px' }}
                    />
                  }
                </IconButton>
              </div>
            </Box>
          </>
        }
      </Paper>
    </FormProvider>
  );
};

IDVerificationChat.defaultProps = {
  loading: false,
  loadTicketDetails: () => {
  },
  setLoading: () => {
  },
  ticketResponse: null
};

IDVerificationChat.propTypes = {
  loading: PropTypes.bool,
  loadTicketDetails: PropTypes.func,
  setLoading: PropTypes.func,
  ticketResponse: PropTypes.object
};

export default IDVerificationChat;
