/* eslint-disable camelcase */
// Packages
import React, { useCallback, useContext, useEffect, useState } from 'react';
import { Grid, Typography } from '@mui/material';
import PropTypes from 'prop-types';

// Relatives
import UserContext from '../../../../contexts/UserContext';
import LayoutContext from '../../../../contexts/LayoutContext';
import UserProfileProveVerifyForm from '../../../ProfileDetails/components/UserProfileProveVerifyForm';

const UserProfileStep2 = props => {
  const { setLoading, onSubmit, response, loading } = props;
  /**
   * App Contexts & Params
   */
  const { params: { mobilePhone } } = useContext(UserContext);
  const {
    setHeader,
    setSubtitle,
    setPageTitle,
    setPageClass,
    setHideLogo
  } = useContext(LayoutContext);
  /**
   * App State
   */
  const [editModeOn, setEditModeOn] = useState(false);
  /**
   * Request Api
   */
  const handleSubmit = (success, data, errorMessage) => {
    onSubmit(success, data, errorMessage);
  };
  /**
   * useCallbacks Functions
   */
  const initialize = useCallback(() => {
    setPageTitle('Verify your account');
    setPageClass('pj-login');
    setHideLogo(true);
  }, [setPageTitle, setPageClass, setHideLogo]);
  const renderEditModeOn = useCallback(() => {
    setHeader(
      <Typography
        color="primary"
        variant="h1"
        className="pj-login-header"
      >
        Is this you?
      </Typography>
    );
    setSubtitle(
      <Typography
        variant="body2"
        className="pj-login-subtitle"
      >
        Let be sure everything is correct
      </Typography>
    );
  }, [setHeader, setSubtitle]);
  const renderEditModeOff = useCallback(() => {
    setHeader(
      <Typography
        color="primary"
        variant="h1"
        className="pj-login-header"
      >
        Verify your account
      </Typography>
    );
    setSubtitle(
      <Typography
        variant="body2"
        className="pj-login-subtitle"
      >
        We sent you a verification code to your mobile phone
      </Typography>
    );
  }, [setHeader, setSubtitle]);
  /**
   * Effects
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  useEffect(() => {
    initialize();
  }, [initialize]);
  useEffect(() => {
    if (!editModeOn) {
      renderEditModeOn();
    } else {
      renderEditModeOff();
    }
  }, [editModeOn, renderEditModeOn, renderEditModeOff]);

  return (
    <Grid
      maxWidth="sm"
      direction="column"
      container
    >
      <UserProfileProveVerifyForm
        setEditModeOn={setEditModeOn}
        setLoading={setLoading}
        loading={loading}
        onSubmit={handleSubmit}
        response={response}
        mobilePhone={mobilePhone}
        editModeOn={editModeOn}
      />
    </Grid>
  );
};

UserProfileStep2.defaultProps = {
  onCancel: null
};

UserProfileStep2.propTypes = {
  setLoading: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  response: PropTypes.any.isRequired,
  onCancel: PropTypes.func
};

export default UserProfileStep2;
