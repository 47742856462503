/* eslint-disable react/no-danger */
// Packages
import React, { useRef, useState } from 'react';
import { Box, Button, Container, IconButton, Modal, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import ReactCrop from 'react-image-crop';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import classNames from 'classnames';

const ImageModalCrop = props => {
  const { openModal, onClose, className, crop, setCrop, src, fileName, onSubmit } = props;
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const fileUrl = useRef(null);
  const imageRef = useRef(null);

  const handleClose = () => onClose();

  const getCroppedImg = (image, crop, fileName) => {
    const canvas = document.createElement('canvas');
    const pixelRatio = window.devicePixelRatio;
    const scaleX = image.naturalWidth / image.width;
    const scaleY = image.naturalHeight / image.height;
    const ctx = canvas.getContext('2d');

    canvas.width = crop.width * pixelRatio * scaleX;
    canvas.height = crop.height * pixelRatio * scaleY;

    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = 'high';

    ctx.drawImage(
      image,
      crop.x * scaleX,
      crop.y * scaleY,
      crop.width * scaleX,
      crop.height * scaleY,
      0,
      0,
      crop.width * scaleX,
      crop.height * scaleY
    );

    return new Promise(resolve => {
      canvas.toBlob(
        blob => {
          if (!blob) {
            // reject(new Error('Canvas is empty'));
            console.log('Canvas is empty');
            return;
          }
          blob.name = fileName;
          window.URL.revokeObjectURL(fileUrl.current);
          fileUrl.current = window.URL.createObjectURL(blob);
          resolve(fileUrl.current);
        },
        'image/jpeg',
        1
      );
    });
  };

  const makeClientCrop = async crop => {
    if (imageRef.current && crop.width && crop.height) {
      const croppedImageUrl = await getCroppedImg(imageRef.current, crop, fileName);
      setCroppedImageUrl(croppedImageUrl);
    }
  };

  const onCropComplete = crop => makeClientCrop(crop);

  const onCropChange = (crop /* , percentCrop */) => {
    // You could also use percentCrop:
    // this.setState({ crop: percentCrop });
    setCrop(crop);
  };

  const onImageLoaded = image => {
    imageRef.current = image;
  };

  const handleClickSubmit = () => {
    onSubmit(croppedImageUrl);
  };

  return (
    <Modal
      className={classNames({ [className]: className })}
      open={openModal}
      onClose={handleClose}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Container maxWidth="sm">
        <Box className="modal-header">
          <Typography
            id="modal-modal-title"
            component="h6"
            className="title"
          >
            Crop your image
          </Typography>
          <IconButton
            aria-label="Close notification"
            color="inherit"
            onClick={handleClose}
          >
            <CloseIcon className="close" />
          </IconButton>
        </Box>
        <Box className="modal-body">
          <ReactCrop
            src={src}
            crop={crop}
            ruleOfThirds
            onImageLoaded={onImageLoaded}
            onComplete={onCropComplete}
            onChange={onCropChange}
          />
          {(
             src || croppedImageUrl
           ) && (
             <div className="container__actions">
               {(
                  src || croppedImageUrl
                ) && (
                  <Button
                    type="button"
                    fullWidth
                    variant="outlined"
                    color="inherit"
                    onClick={handleClose}
                  >
                    Cancel
                  </Button>
                )}
               {src && croppedImageUrl && (
                 <Button
                   type="button"
                   fullWidth
                   color="primary"
                   onClick={handleClickSubmit}
                 >
                   Upload
                 </Button>
               )}
             </div>
           )}
        </Box>
      </Container>
    </Modal>
  );
};

ImageModalCrop.defaultProps = {
  className: '',
  src: '',
  fileName: '',
  crop: null,
  setCrop: noop,
  openModal: false,
  onClose: noop,
  onSubmit: noop
};

ImageModalCrop.propTypes = {
  className: PropTypes.string,
  src: PropTypes.string,
  fileName: PropTypes.string,
  crop: PropTypes.object,
  setCrop: PropTypes.func,
  openModal: PropTypes.bool,
  onClose: PropTypes.func,
  onSubmit: PropTypes.func
};

export default ImageModalCrop;
