import get from 'lodash/get';
import React, { forwardRef, useCallback, useContext, useEffect, useState } from 'react';
import { isNil } from 'lodash';
import { Alert, AppBar, Box, Button, Dialog, IconButton, List, ListItem, Slide, Toolbar, Typography } from '@mui/material';
import CloseIcon from '@mui/icons-material/Close';
import http from '../../../services/api/http';
import AppContext from '../../../contexts/AppContext';
import UserContext from '../../../contexts/UserContext';
import NotificationContext from '../../../contexts/NotificationContext';
import IDVerificationChat from '../IDVerificationChat/IDVerificationChat';
import ChipStatus from '../../../components/common/ChipStatus/ChipStatus';

const Transition = forwardRef(function Transition(props, ref) {
  return <Slide
    direction="up"
    ref={ref} {...props} />;
});

const IDVerificationStatus = () => {
  /**
   * App Contexts & Params
   */
  const { apiHost } = useContext(AppContext);
  const { token, params } = useContext(UserContext);
  /**
   * App State
   */
  const userVerifiedStatus = get(params, 'userVerifiedStatus', '');
  const { notificationError } = useContext(NotificationContext);
  const [ticketResponse, setTicketResponse] = useState(null);
  const [loading, setLoading] = useState(false);
  const [open, setOpen] = useState(false);
  /**
   * Handlers
   */
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);
  const renderAlertMessage = () => {
    const parameters = {
      PENDING: {
        type: 'info',
        message: 'Your account is pending verification',
        icon: 'test'
      },
      VERIFIED: {
        type: 'success',
        message: null,
        icon: 'test'
      },
      REJECTED: {
        type: 'error',
        message: 'Your account has not been approved',
        icon: 'test'
      },
      IN_REVIEW: {
        type: 'warning',
        message: 'Your account is in review',
        icon: 'test'
      }
    };

    return (
      !isNil(parameters[userVerifiedStatus]?.message) &&
      !isNil(ticketResponse, '_related.ticket', null) &&
      <>
        <Alert
          severity={parameters[userVerifiedStatus]?.type}
          variant="filled"
          sx={{ width: '100%' }}
        >
          <Typography
            variant="small"
            paragraph={false}
          >{parameters[userVerifiedStatus]?.message}</Typography>
        </Alert>
        <Button
          type="button"
          variant="contained"
          color="secondary"
          size="small"
          onClick={handleOpen}
          sx={{ display: 'flex', ml: 'auto', mb: 3 }}
        >Open Conversation
        </Button>
        <Dialog
          fullScreen
          open={open}
          onClose={handleClose}
          TransitionComponent={Transition}
        >
          <AppBar sx={{ position: 'relative' }}>
            <Toolbar>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
              <Typography
                sx={{ ml: 2, flex: 1 }}
                variant="h6"
                component="div"
              >
                {get(ticketResponse, '_related.ticket._related.department.name', '')}
              </Typography>
              <Box>
                <ChipStatus status={get(ticketResponse, '_related.ticket.status', '')} />
              </Box>
            </Toolbar>
          </AppBar>
          <List>
            <ListItem>
              <IDVerificationChat
                loadTicketDetails={loadTicketDetails}
                loading={loading}
                setLoading={setLoading}
                ticketResponse={ticketResponse}
              />
            </ListItem>
          </List>
        </Dialog>
      </>
    );
  };
  const loadTicketDetails = async ticketId => {
    setLoading(true);
    const ticketDetailsResponse = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v2/manual-entity-verification/${ticketId}`)
      .catch(err => {
        console.log(err);
        notificationError('An error occurred validating your profile, please contact support.');
      });

    if (!ticketDetailsResponse || !ticketDetailsResponse.data) {
      setLoading(false);
      return;
    }

    const { success, output, error } = get(ticketDetailsResponse, 'data', {});

    if (!success && error) {
      console.log(error);
      if (error.message) {
        notificationError(error.message);
      }
    } else {
      setTicketResponse(output);
    }
  };
  const getVerificationIdTicketList = useCallback(async () => {
    if (!token) {
      return;
    }

    setLoading(true);
    const ticketList = await http(apiHost, token.accessToken, token.tokenType)
      .get('/api/web/v2/manual-entity-verification')
      .catch(err => {
        console.log(err);
        notificationError('An error occurred validating your profile, please contact support.');
      });

    if (!ticketList || !ticketList.data) {
      setLoading(false);
      return;
    }

    const { success, output, error } = get(ticketList, 'data', {});

    if (!success && error) {
      console.log(error);
      if (error.message) {
        notificationError(error.message);
      }
    } else {
      const data = get(output, 'data', null);
      if (data) {
        if (data.length > 0) {
          loadTicketDetails(data[0].id).finally(() => setLoading(false));
        }
      } else {
        notificationError('Valid entity not found');
      }
    }
    // TODO: eslint-disable-next-line react-hooks/exhaustive-deps

  }, []);
  useEffect(() => {
    getVerificationIdTicketList().finally();
  }, [getVerificationIdTicketList]);

  return renderAlertMessage();
};

IDVerificationStatus.defaultProps = {};

IDVerificationStatus.propTypes = {};

export default IDVerificationStatus;
