import http from './http';

export const UploadImage = (apiHost, accessToken, tokenType, file, uploadProgressBanner) => {
  const formData = new FormData();
  formData.append('file', file.file);
  return http(apiHost, accessToken, tokenType, { 'Content-Type': 'multipart/form-data' }, uploadProgressBanner)
    .post('/api/web/media/manage/upload', formData);
};

export const UploadSupportAttachment = (
  // eslint-disable-next-line camelcase
  apiHost, accessToken, tokenType, uploadProgressBanner, ticket_head_id, ticket_body_id, media_id
) => {
  return http(apiHost, accessToken, tokenType, {}, uploadProgressBanner)
    .post('/api/web/v2/support/create-attachment', {
      // eslint-disable-next-line camelcase
      ticket_head_id,
      ticket_body_id: null,
      // eslint-disable-next-line camelcase
      media_id
    });
};
