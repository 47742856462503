/* eslint-disable camelcase */
// Packages
import React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import { Typography } from '@mui/material';
import get from 'lodash/get';

// Relatives
import UserModel from '../../models/UserModel';

export const UserProfileMusicGenres = props => {
  const { profileData, customClass } = props;
  const genreDetails = get(profileData, 'genreDetails', []);
  return (
    genreDetails?.length > 0 ?
      (
        genreDetails.map(genreItem => {
          return (
            <Chip
              className={customClass}
              color="primary"
              key={`genre-${genreItem.id}`}
              label={genreItem.genreName}
              variant="filled"
              sx={{
                m: .75,
                color: 'white'
              }}
            />
          );
        })
      ) :
      <Typography
        variant="body2"
        textAlign="center"
      >No music genre selected.</Typography>
  );
};

UserProfileMusicGenres.defaultProps = {
  customClass: null
};

UserProfileMusicGenres.propTypes = {
  customClass: PropTypes.string,
  profileData: PropTypes.shape(UserModel).isRequired
};


export default UserProfileMusicGenres;
