/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Grid, ImageListItem, ImageListItemBar, Link, Typography } from '@mui/material';
import { PICKLEJAR_DEFAULT_PDF_IMAGE, PICKLEJAR_DEFAULT_VIDEO_IMAGE, picklejarTextColorLight } from '../../../const/PicklejarTheme';

const AttachmentMediaList = props => {
  const { attachmentList } = props;
  const renderMediaImage = (type, medium_image) => {
    switch (true) {
      case type.includes('pdf'):
        return PICKLEJAR_DEFAULT_PDF_IMAGE;

      case type.includes('webm'):
      case type.includes('mp4'):
      case type.includes('ogg'):
        return PICKLEJAR_DEFAULT_VIDEO_IMAGE;

      default:
        return medium_image;
    }
  };

  return (
    <Grid
      container
      spacing={2}
      component="ul"
      sx={{ px: 0 }}
    >
      {
        attachmentList.map((attachment, index) => {
          const { medium_image, title, alt, description, url, type } = attachment._misc.media;
          return <Grid
            component="li"
            item
            xs={12}
            sm={6}
            lg={3}
            xl={2}
            alignItems="flex-start"
            display="flex"
            key={`attachment-media-list-${index}`}
            className="attachment-media-list"
          >
            <ImageListItem
              component="div"
              key={`attachment-header-${index}`}
              className="attachment-media-list-item"
            >
              <Link
                href={url}
                target="_blank"
                className="attachment-media-list-link"
              >
                <img
                  src={renderMediaImage(type, medium_image)}
                  alt={title || alt}
                  loading="lazy"
                  className={!type.includes('image') ? 'attachment-media-item-icon' : 'attachment-media-item-image'}
                />
                <ImageListItemBar
                  title={<Typography sx={{ fontSize: '12px', color: picklejarTextColorLight }}>{title}</Typography>}
                  subtitle={
                    <Typography sx={{ fontSize: '8px' }}>{description}</Typography>
                  }
                />
              </Link>
            </ImageListItem>
          </Grid>;
        })
      }
    </Grid>
  );
};

AttachmentMediaList.propTypes = {
  attachmentList: PropTypes.any.isRequired
};

export default AttachmentMediaList;
