/* eslint-disable camelcase */
import React from 'react';
import PropTypes from 'prop-types';
import { Check, Close, DeleteSharp } from '@mui/icons-material';
import { Avatar, Box, IconButton, LinearProgress, List, ListItem, ListItemIcon, ListItemText, Typography } from '@mui/material';

const updateStatus = status => {
  switch (status) {
    case 'success':
      return <Check color="success" />;
    case 'error':
      return <Close color="error" />;
    default:
      return <DeleteSharp />;
  }
};

const AttachmentUploadList = props => {
  const { attachmentList, isLoading, removeAttachment } = props;
  return (
    attachmentList.length > 0 &&
    <List
      component="div"
      disablePadding
    >
      {attachmentList.map((file, index) => {
          const { status, _misc: { media: { alt, medium_image, title, size } } } = file;
          const disableButton = (
                                  status !== 'pending'
                                ) || isLoading;
          return <ListItem
            divider
            key={`attachment-${index}-${title}-${status}`}
            disabled={disableButton}
            secondaryAction={
              <IconButton
                edge="end"
                aria-label="delete"
                key={`attachment-delete-${index}`}
                disableRipple={disableButton}
                disableFocusRipple={disableButton}
                disableTouchRipple={disableButton}
                disabled={disableButton}
                onClick={() => {
                  if (status !== 'success') {
                    removeAttachment(index);
                  }
                }}
              >
                {updateStatus(status)}
              </IconButton>
            }
            alignItems="flex-start"
          >
            <ListItemIcon>
              <Avatar
                alt={`${alt}attachmentIndex`}
                src={medium_image}
                key={`attachment-avatar-${title}`}
              />
            </ListItemIcon>

            {(
              status === 'loading'
            ) ?
              <Box sx={{ width: '100%', alignSelf: 'center', mx: 3 }}>
                <LinearProgress
                  variant="indeterminate"
                  color="primary"
                />
              </Box> :
              <ListItemText
                key={`attachment-description-${title}`}
                sx={{ mx: 2 }}
                primary={
                  <Typography
                    variant="body2"
                    color="text.primary"
                  >{title} </Typography>
                }
                secondary={
                  <Typography
                    sx={{ display: 'block' }}
                    component="span"
                    variant="body2"
                    color="text.secondary"
                  >
                    {size}
                  </Typography>
                }
              />
            }
          </ListItem>;
        }
      )}
    </List>
  );
};

AttachmentUploadList.defaultProps = {
  isLoading: false,
  removeAttachment: null
};

AttachmentUploadList.propTypes = {
  attachmentList: PropTypes.any.isRequired,
  isLoading: PropTypes.bool,
  removeAttachment: PropTypes.func
};

export default React.memo(AttachmentUploadList);
