import { createContext } from 'react';

export const IDVerificationContext = createContext({
  defaultBackIDPreviewUrl: '',
  defaultFrontIDPreviewUrl: '',
  defaultVideoPreviewUrl: '',
  iDVerificationFrontImageId: '',
  iDVerificationBackImageId: '',
  iDVerificationVideoId: '',
  supportTicket: null,
  setDefaultBackIDPreviewUrl: () => null,
  setDefaultFrontIDPreviewUrl: () => null,
  setDefaultVideoPreviewUrl: () => null,
  setIDVerificationFrontImageId: () => null,
  setIDVerificationBackImageId: () => null,
  setIDVerificationVideoId: () => null,
  setSupportTicket: () => null
});

export default IDVerificationContext;
