/* eslint-disable camelcase */
import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { Button, Container, FormControl, Grid, List, ListItem, TextField, Typography } from '@mui/material';
import get from 'lodash/get';
import AppContext from '../../../contexts/AppContext';
import UserContext from '../../../contexts/UserContext';
import http from '../../../services/api/http';
import IDVerificationContext from '../../../contexts/IDVerificationContext';

const IDVerificationStep4 = props => {
  const {
    loading,
    setLoading,
    onSubmit
  } = props;
  /**
   * App Contexts & Params
   */
  const { apiHost } = useContext(AppContext);
  const { token, getUser } = useContext(UserContext);
  const { supportTicket } = useContext(IDVerificationContext);
  const { first_name, last_name, phone_1 } = get(getUser(), 'params.profile', {});
  const id_head = get(supportTicket, '_related.ticket.id', null);
  const idVerificationForm = useForm({
    defaultValues: {
      first_name,
      last_name,
      phone: phone_1,
      text: ''
    }
  });
  const { control, setError, handleSubmit } = idVerificationForm;

  const submitForm = async formData => {
    setLoading(true);
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .post('/api/web/v2/support/support-body', {
        id_head,
        text: formData.text
      })
      .catch(error => console.log(error));
    setLoading(false);
    const { success, error, data } = get(response, 'data', {});

    let message = '';
    if (!success && data) {
      Object.keys(data).forEach(field => setError(field, { type: 'required', message: get(data, `${field}.0`) }));
      message += 'Please try again.';
    }

    if (!success && error && error.fieldErrors) {
      message += error.fieldErrors[0].message;
    }

    onSubmit(success, data, message);
  };
  return (
    <Container maxWidth="sm">
      <FormProvider {...idVerificationForm}>
        <Grid
          container
          component="form"
          onSubmit={handleSubmit(submitForm)}
          autoComplete="off"
          className="pj-user-profile__form"
          maxWidth="md"
        >
          <Grid
            item
            xs={12}
          >
            <List dense>
              <ListItem disablePadding>
                <Controller
                  name="first_name"
                  control={control}
                  rules={{ required: 'First Name is required' }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="First Name"
                      className="pj-light-underline"
                      variant="standard"
                      color="primary"
                      helperText={
                        fieldState.error &&
                        (
                          <Typography
                            variant="p"
                            color="error"
                          >
                            {fieldState.error.message}
                          </Typography>
                        )
                      }
                    />
                  )}
                />
              </ListItem>

              <ListItem disablePadding>
                <Controller
                  name="last_name"
                  control={control}
                  rules={{ required: 'Last Name is required' }}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      fullWidth
                      label="Last Name"
                      className="pj-light-underline"
                      variant="standard"
                      color="primary"
                      helperText={
                        fieldState.error &&
                        (
                          <Typography
                            variant="p"
                            color="error"
                          >
                            {fieldState.error.message}
                          </Typography>
                        )
                      }
                    />
                  )}
                />
              </ListItem>

              <ListItem disablePadding>
                <FormControl className="pj-light-underline">
                  <Controller
                    name="text"
                    control={control}
                    rules={{ required: 'Message required' }}
                    render={({ field, fieldState }) => (
                      <TextField
                        {...field}
                        multiline
                        maxRows={4}
                        fullWidth
                        label="Message"
                        className="pj-light-underline"
                        variant="standard"
                        color="primary"
                        helperText={
                          fieldState.error &&
                          (
                            <Typography
                              variant="p"
                              color="error"
                            >
                              {fieldState.error.message}
                            </Typography>
                          )
                        }
                      />
                    )}
                  />
                </FormControl>
              </ListItem>

              <ListItem>
                <Button
                  type="submit"
                  variant="contained"
                  color="primary"
                  disabled={loading === true}
                  fullWidth
                >
                  Send Message
                </Button>
              </ListItem>
            </List>
          </Grid>
        </Grid>
      </FormProvider>
    </Container>
  );
};

IDVerificationStep4.defaultProps = {
  previewUrl: null
};

IDVerificationStep4.propTypes = {
  loading: PropTypes.bool.isRequired,
  previewUrl: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  response: PropTypes.any.isRequired,
  setLoading: PropTypes.func.isRequired
};


export default IDVerificationStep4;
