// Packages
import React, { useContext, useEffect } from 'react';
import { Box, Button, Container } from '@mui/material';
import { useNavigate } from 'react-router';
import PropTypes from 'prop-types';

// Relatives
import UserProfileBirthDayGenderForm from '../../../ProfileDetails/components/UserProfileBirthDayGenderForm';
import UserContext from '../../../../contexts/UserContext';

const UserProfileStep1 = props => {
  const { onSubmit, setLoading, loading } = props;
  /**
   * App Contexts & Params
   */
  const { logout } = useContext(UserContext);
  const navigate = useNavigate();
  /**
   * Handlers
   */
  const loginCancel = () => {
    logout();
    navigate('/');
  };
  /**
   * Effects
   */
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  /**
   * Api Requests
   */
  const handleSubmitForm = (success, data, errorMessage) => {
    onSubmit(success, data, errorMessage);
  };
  return (
    <Box className="pj-user-profile__form pj-full-width">
      <UserProfileBirthDayGenderForm
        loading={loading}
        setLoading={setLoading}
        onSubmit={handleSubmitForm}
      />
      <Container
        maxWidth="sm"
        className="pj-d-flex pj-justify-content-center"
      >
        <Button
          type="button"
          color="inherit"
          variant="text"
          onClick={loginCancel}
          fullWidth
          sx={{ mt: 4, fontSize: '10px' }}
        >
          Logout
        </Button>
      </Container>
    </Box>
  );
};

UserProfileStep1.defaultProps = {
  id: null,
  onCancel: null
};

UserProfileStep1.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  loading: PropTypes.bool.isRequired,
  setLoading: PropTypes.func.isRequired,
  id: PropTypes.number,
  onCancel: PropTypes.func
};

export default UserProfileStep1;
