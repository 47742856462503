// Packages
import React, { useContext, useEffect, useState } from 'react';
import { Box, CircularProgress, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import noop from 'lodash/noop';
import { useNavigate } from 'react-router-dom';
import get from 'lodash/get';

// Relatives
import AppContext from '../../../contexts/AppContext';
import UserContext from '../../../contexts/UserContext/UserContext';
import NotificationContext from '../../../contexts/NotificationContext';
import LayoutContext from '../../../contexts/LayoutContext';
import http from '../../../services/api/http';
import UserProfileStep1 from './UserProfileSteps/UserProfileStep1';
import UserProfileStep2 from './UserProfileSteps/UserProfileStep2';
import UserProfileStep3 from './UserProfileSteps/UserProfileStep3';

const UserProfileSetup = props => {
  const { updateMode, id } = props;
  /**
   * App Contexts & Params
   */
  const { apiHost } = useContext(AppContext);
  const { token, setUserParams, setUserStatus, params } = useContext(UserContext);
  const { notificationSuccess, notificationError } = useContext(NotificationContext);
  const {
    setHeader,
    setSubtitle,
    setPageTitle,
    setPageClass,
    setHideLogo
  } = useContext(LayoutContext);
  /**
   * App State
   */
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const [response, setResponse] = useState({});
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  /**
   * Handlers
   */
  const nextStep = (success, data, message = '') => {
    setLoading(false);

    if (data) {
      setResponse(data);
    }

    if (success) {
      if (activeStep < steps.length - 1) {
        setActiveStep(state => state + 1);
      } else {
        const profile = get(data, 'profile', []);
        const status = get(data, 'status', []);
        setUserParams({ profile });
        setUserStatus(status);

        if (status === 'active') {
          message = 'User information successfully updated';
          navigate('/verify-id');
        } else {
          message = 'There was an error activating your user';
          success = false;
          navigate('/');
        }
      }
    }

    if (message) {
      if (success) {
        notificationSuccess(message);
      } else {
        notificationError(message);
      }
    }
  };
  const steps = [
    {
      label: 'Create your Account',
      description: 'Pick a username and become an instant fan, <br/>you can always change this later.',
      content: <UserProfileStep1
        onSubmit={nextStep}
        setLoading={setLoading}
        loading={loading}
      />
    },
    {
      label: 'Can I Get your Autograph?',
      description: 'Enter your basic info, We\'ll keep it safe',
      content: <UserProfileStep2
        onSubmit={nextStep}
        setLoading={setLoading}
        loading={loading}
        response={response}
      />
    },
    {
      label: 'Tell us about you!',
      description: null,
      content: <UserProfileStep3
        onSubmit={nextStep}
        setLoading={setLoading}
        loading={loading}
        response={response}
      />
    }
  ];
  const loadModel = async () => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v1/user/profile?id=${id}`)
      .catch(() => {
      });

    const { success, error } = get(response, 'data', {});
    if (!success) {
      console.warn(error);
      setLoading(false);

      return;
    }
    setLoading(false);
  };
  /**
   * Effects
   */
  useEffect(() => {
    window.scrollTo(0, 0);
    setUserParams({ phase: 'phase3' });
    if (isFirstLoad) {
      setIsFirstLoad(false);
      if (params && params.activeStep) {
        setActiveStep(params.activeStep);
      }
    }
    // TODO: eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    if (updateMode && id) {
      loadModel();
    }
    setPageTitle('Login');
    setHideLogo(true);
    setHeader(
      <Typography
        color="primary"
        variant="h1"
        className="pj-login-header"
        dangerouslySetInnerHTML={{ __html: steps[activeStep].label }}
      />
    );
    setSubtitle(
      <Typography
        variant="body2"
        className="pj-login-subtitle"
        dangerouslySetInnerHTML={{ __html: steps[activeStep].description }}
      />
    );
    setPageTitle('Create Profile');
    setPageClass('UserProfile');
    // TODO: eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  useEffect(() => {
    setUserParams({ activeStep });
    // TODO: eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeStep]);
  useEffect(() => {
    setLoading(loading);
  }, [loading]);

  return (
    <>
      <Box className={loading ? 'loading-overlay' : 'loading-overlay hidden'}>
        <CircularProgress color="primary" />
      </Box>
      {steps[activeStep].content}
    </>
  );
};

UserProfileSetup.defaultProps = {
  onSubmit: noop,
  onCancel: noop,
  id: null,
  updateMode: false
};

UserProfileSetup.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  id: PropTypes.number,
  updateMode: PropTypes.bool
};

export default UserProfileSetup;
