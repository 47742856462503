// Packages
import React, { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import get from 'lodash/get';

// Relatives
import UserContext from './UserContext';
import AppContext from '../AppContext';
import NotificationContext from '../NotificationContext';
import { clearState, loadState, saveState } from '../../services/session/localStorage';
import http from '../../services/api/http';

const UserProvider = props => {
  const { children, sessionKey } = props;
  const { apiHost } = useContext(AppContext);
  /**
   * App Contexts & Params
   */
  const { notificationError } = useContext(NotificationContext);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  /**
   * App State
   */
  const [id, setId] = useState(() => get(loadState(sessionKey), 'id'));
  const [token, setToken] = useState(get(loadState(sessionKey), 'token'));
  const [status, setStatus] = useState(get(loadState(sessionKey), 'status'));
  const [params, setParams] = useState(get(loadState(sessionKey), 'params'));
  const [canEditProfile, setCanEditProfile] = useState(false);
  const setUser = useCallback(
    user => {
      const { id, token, status, params } = user;
      setId(id);
      setToken(token);
      setParams(params);
      setStatus(status);
      saveState(sessionKey, { id, token, status, params });
    },
    [setId, setToken, setStatus, setParams]
  );
  const setUserStatus = useCallback(
    status => {
      setStatus(status);
      saveState(sessionKey, { id, token, status, params });
    },
    [setStatus, id, token, params]
  );
  const setUserParams = useCallback(
    params => {
      setParams(state => {
        saveState(sessionKey, { id, token, status, params: { ...state, ...params } });

        return { ...state, ...params };
      });
    },
    [setParams, id, token, status]
  );
  const getUser = useCallback(() => {
    if (id && token) {
      return { id, token, status, params };
    }

    return null;
  }, [id, token, status, params]);
  const logout = useCallback(() => {
    clearState(sessionKey);
    setId(null);
    setToken(null);
    setStatus(null);
    setParams(null);

    setTimeout(() => {
      window.location.reload();
    }, 200);

  }, [sessionKey]);
  /**
   * API Calls
   */
  const fetchUser = async () => {
    const response = await http(apiHost, token.accessToken, token.tokenType)
      .get(`/api/web/v2/users/me`)
      .catch(() => {
        logout();
        navigate('/');
      });

    setLoading(false);
    if (!response || !response.data) {
      return [];
    }

    const { output, success } = get(response, 'data');
    if (!success || !output) {
      return [];
    }

    const handle = get(output, 'handle', []);
    const profile = get(output, '_related.profile', []);
    const active = get(output, '_misc.active', []);
    const userVerifiedStatus = active === true ? get(output, 'user_verified_status', '') : '';
    setUserParams({ handle, profile, userVerifiedStatus });
    setStatus(active === true ? 'active' : 'inactive');

    return output;
  };
  /**
   * Effects
   */
  useEffect(() => {
    if (token) {
      fetchUser().catch(err => notificationError(err.message));
    } else {
      setLoading(false);
    }
  }, [token, status]);
  const isLogged = useMemo(() => !!id && !!token && status === 'active', [id, token, status]);
  const userValueMemo = useMemo(
    () => (
      {
        id,
        token,
        status,
        params,
        canEditProfile,
        getUser,
        setUser,
        setUserStatus,
        setUserParams,
        setCanEditProfile,
        isLogged,
        logout
      }
    ),
    [
      id,
      token,
      status,
      params,
      canEditProfile,
      getUser,
      setUser,
      setUserStatus,
      setUserParams,
      setCanEditProfile,
      isLogged,
      logout
    ]
  );

  if (loading) {
    return null;
  }

  return <UserContext.Provider value={userValueMemo}>{children}</UserContext.Provider>;
};

UserProvider.defaultProps = {
  children: null,
  sessionKey: ''
};

UserProvider.propTypes = {
  children: PropTypes.node,
  sessionKey: PropTypes.string
};

export default UserProvider;
