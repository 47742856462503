/* eslint-disable camelcase */
// Packages
import * as React from 'react';
import PropTypes from 'prop-types';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';

const ClaimsStatus = props => {
  const { status } = props;

  const getType = status => {
    switch (status) {
      case 'review': {
        return 'warning';
      }

      case 'pending': {
        return 'success';
      }

      case 'declined': {
        return 'error';
      }

      case 'processing': {
        return 'info';
      }

      default:
        return 'info';
    }
  };
  return (
    <Stack
      direction="row"
      justifyContent="end"
      alignItems="center"
      spacing={1}
      className="chip"
    >
      <Chip
        label={status}
        color={getType(status)}
        sx={{ textTransform: 'uppercase', fontWeight: 'bold' }}
      />
    </Stack>
  );
};

ClaimsStatus.defaultProps = {
  status: null
};

ClaimsStatus.propTypes = {
  status: PropTypes.string
};

export default ClaimsStatus;
