// Packages
import React from 'react';
import PropTypes from 'prop-types';
import { List, ListItem, ListItemText, Typography } from '@mui/material';
import UserModel from '../../models/UserModel';

// Relatives


export const UserProfilePersonalData = props => {
  const genders = {
    M: 'Male',
    F: 'Female'
  };
  const {
    profileData: {
      birthday,
      gender,
      handle
    }
  } = props;

  return (
    <List dense>
      <ListItem disablePadding>
        <ListItemText
          primary={<Typography variant="labels">Handle</Typography>}
          secondary={<Typography
            variant="body2"
            component="h5"
          >{handle}</Typography>}
        />
      </ListItem>
      <ListItem disablePadding>
        <ListItemText
          primary={<Typography variant="labels">Birthday</Typography>}
          secondary={<Typography
            variant="body2"
            component="h5"
          >{birthday}</Typography>}
        />
      </ListItem>
      <ListItem disablePadding>
        <ListItemText
          primary={<Typography variant="labels">Gender</Typography>}
          secondary={<Typography
            variant="body2"
            component="h5"
          >{genders[gender]}</Typography>}
        />
      </ListItem>
    </List>
  );
};

UserProfilePersonalData.propTypes = {
  profileData: PropTypes.shape(UserModel).isRequired
};
export default UserProfilePersonalData;
